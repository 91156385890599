import React from 'react';

function Settings() {
  return (
    <div id="settings" style={{display: 'none', "width":"980px","height":"563px"}} className="bigmsg nicefont">
      <div className="bigmsg_close" onMouseDown={() => { /* showBigDiv(null) */ }}>&#10005;</div>
      <div className="bigmsg_title boldfont">Settings</div>

      <div id="settings_icons" style={{"position":"absolute","top":"43px","left":"0px","height":"520px","width":"80px","background":"#0006"}}>
        <div id="settings_icon0" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(0) */ }}>
          <i style={{"position":"absolute","top":"3px","left":"25px","fontSize":"36px"}} className="fas fa-user"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px"}} className="settings_icon_text">account</div>
        </div>
        <div id="settings_icon1" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(1) */ }}>
          <i style={{"position":"absolute","top":"1px","left":"21px","fontSize":"37px"}} className="fas fa-user-cog"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px"}} className="settings_icon_text">options</div>
        </div>
        <div id="settings_icon2" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(2) */ }}>
          <i style={{"position":"absolute","top":"-1px","left":"19px","fontSize":"42px"}} className="fas fa-volume-up"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px"}} className="settings_icon_text">audio</div>
        </div>
        <div id="settings_icon3" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(3) */ }}>
          <i style={{"position":"absolute","top":"0px","left":"21px","fontSize":"40px"}} className="fas fa-headphones"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px","letterSpacing":"-0.5px"}} className="settings_icon_text">headphones</div>
        </div>
        <div id="settings_icon4" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(4) */ }}>
          <i style={{"position":"absolute","top":"0px","left":"21px","fontSize":"38px"}} className="fas fa-download"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px"}} className="settings_icon_text">shortcut</div>
        </div>
        <div id="settings_icon5" className="settings_icon" onMouseDown={() => { /* settings_panel_goto(5) */ }}>
          <i style={{"position":"absolute","top":"30px","left":"14px","fontSize":"26px"}} className="fas fa-midi"></i>
          <div style={{"position":"absolute","top":"57px","left":"0px"}} className="settings_icon_text">midi</div>
        </div>
      </div>

      <div id="settings_panel" className="settings_panel" style={{"position":"absolute","top":"45px","left":"80px","height":"518px","width":"870px","paddingLeft":"30px","overflowX":"hidden","overflowY":"scroll"}}>
        <div style={{"position":"absolute","right":"22px","top":"35px","color":"#AAA","fontSize":"16px"}}>Beatport DJ v0.5.10</div>
        <table id="settings_table" width="840">
          <tr>
            <td valign="top" colSpan="2">
              <div className="settings_title" style={{"paddingTop":"30px","paddingBottom":"0px"}}>Your LINK account</div>
              <div id="userinfo" style={{"fontSize":"16px","lineHeight":"29px"}}></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="420">
              <div className="settings_title" style={{"paddingTop":"40px","paddingBottom":"10px"}}>Player settings</div>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="a03" className="css-checkbox" />
                <label for="a03" className="css-label">auto play at each song loading</label>
                <span className="info1"></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="a12" className="css-checkbox" />
                <label for="a12" className="css-label">enable load song security</label>
                <span className="info1"></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b05" className="css-checkbox" />
                <label for="b05" className="css-label">enable KEYLOCK all the time</label>
                <span className="info1"></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b20" className="css-checkbox" />
                <label for="b20" className="css-label">enable slowdown effect on stop</label>
                <span className="info1" ></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b06" className="css-checkbox" />
                <label for="b06" className="css-label">enable AutoBPM</label>
                <span className="info1" ></span>
              </p>
            </td>
            <td valign="top">
              <div style={{"paddingTop":"57px","paddingBottom":"10px"}}></div>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="a13" className="css-checkbox" />
                <label for="a13" className="css-label">disable fullscreen automix</label>
                <span className="info1" ></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b19" className="css-checkbox" />
                <label for="b19" className="css-label">disable quantized cues</label>
                <span className="info1" ></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b07" className="css-checkbox" />
                <label for="b07" className="css-label">disable reset EQ at load</label>
                <span className="info1"></span>
              </p>
              <p style={{"height":"18px"}}>
                <input onClick={() => { /* settings_cb(this) */ }} type="checkbox" id="b16" className="css-checkbox" />
                <label for="b16" className="css-label">disable EQ isolator mode</label>
                <span className="info1"></span>
              </p>
            </td>
          </tr>
          <tr>
            <td style={{"position":"relative"}} valign="top" colSpan="2">
              <div className="settings_title" style={{"paddingTop":"40px","paddingBottom":"15px"}}>Audio settings</div>
              <div id="audioinfo" style={{"fontSize":"16px","lineHeight":"29px"}}></div>
            </td>
          </tr>
          <tr>
            <td style={{"position":"relative"}} height="330" valign="top" colSpan="2">
              <div className="settings_title" style={{"paddingTop":"43px","paddingBottom":"15px"}}>Headphones settings
                <span className="info1"></span>
              </div>
              <div id="settings_hp0" onMouseDown={() => { /* settings_hp(0) */ }} style={{"position":"absolute","top":"92px","left":"0px"}} className="settings_block settings_block1 settings_block_active"><div style={{"lineHeight":"28px","fontSize":"20px","marginTop":"40px","marginBottom":"17px"}}>No headphones preview</div><span style={{"fontSize":"13px","lineHeight":"16px","color":"#888"}}>output 1 :<br/>speakers stereo  output</span></div>
              <div id="settings_hp1" onMouseDown={() => { /* settings_hp(1) */ }} style={{"position":"absolute","top":"92px","left":"210px"}} className="settings_block settings_block1"><div style={{"lineHeight":"28px","fontSize":"19px","marginTop":"40px","marginBottom":"20px"}}>Headphones preview with mono Y splitter</div><span style={{"fontSize":"13px","lineHeight":"16px","color":"#888"}}>output 1 left channel :<br/>speakers mono output<br/><br/>output 1 right channel :<br/>headphones mono output</span></div>
              <div id="settings_hp2" onMouseDown={() => { /* settings_hp(2) */ }} style={{"position":"absolute","top":"92px","left":"420px"}} className="settings_block settings_block1"><div style={{"lineHeight":"23px","fontSize":"19px","marginTop":"34px","marginBottom":"15px"}}>Headphones preview with multichannel soundcard</div><span style={{"fontSize":"13px","lineHeight":"16px","color":"#888"}}>output 1 :<br/>speakers stereo output<br/><br/>output 2 :<br/>headphones stereo output</span></div>
              <div id="settings_hp3" onMouseDown={() => { /* settings_hp(3) */ }} style={{"position":"absolute","top":"92px","left":"630px"}} className="settings_block settings_block1"><div style={{"lineHeight":"28px","fontSize":"19px","marginTop":"40px","marginBottom":"20px"}}>Headphones preview with external mixer</div><span style={{"fontSize":"13px","lineHeight":"16px","color":"#888"}}>output 1 :<br/>player 1 stereo output<br/><br/>output 2 :<br/>player 2 stereo output</span></div>
            </td>
          </tr>
          <tr>
            <td style={{"position":"relative","lineHeight":"29px","fontSize":"16px"}} height="100"  valign="top" colSpan="2">
              <div className="settings_title" style={{"paddingTop":"40px","paddingBottom":"10px"}}>Beatport DJ shortcut</div>
              Instead of accessing Beatport DJ on your browser, you can set a special shortcut on your computer. Launching the app from this shortcut will mimic the look and feel of a native app (no address bar...).<br/>
            </td>
          </tr>
          <tr><td valign="top" colSpan="2">
            <div id="midipanel" style={{"fontSize":"16px","lineHeight":"29px","marginTop":"30px"}}></div>
            <div id="cinfo" style={{"color":"#AAA","padding":"10px 0"}}></div>
            <div id="dinfo" style={{"color":"#333","padding":"10px 0"}}></div>
            <div id="partymode_master" onMouseUp={() => { /*partymode_recover_force() */} } style={{"display":"none","cursor":"pointer","fontSize":"11.5pt","color":"#FFF","padding":"7px 10px","marginBottom":"30px","background":"#F70"}}>PartyMode enabled, you are the master of the session, click here to force resync all slaves. (if session stuck)</div>
          </td></tr>
        </table>
      </div>
    </div>
  )
}

export default Settings;
