import React from 'react';

function Topbar() {
  return (
    <>
      <div id="alpha1" style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', minWidth:1410, top:0, height:0, background: '#000' }}>
        <div id="topbar" style={{ position: 'relative', width: '100%', height:0, maxWidth: 1410 }} />
      </div>
    </>
  )
}

export default Topbar;
