import React from 'react';
import PropTypes from 'prop-types';

function IMixPause({ size, color = `#E5E5E5`, className }) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M8.33333 3.33325H5V16.6666H8.33333V3.33325Z" stroke={color} fill={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M15 3.33325H11.6667V16.6666H15V3.33325Z" stroke={color} fill={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixPause.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
}

export default IMixPause;
