import React, { useState } from 'react';

import Player from './player/Player';
import AutomixWrapper from './automix/AutomixWrapper';
import CoverFlowWrapper from './coverflow/CoverFlowWrapper';

// The State App Management
import { Provider } from "react-redux";
import store from "../store/index.js";

import './Root.css';

import {
  setAutoBpmAutomix, setBpmAutomix, setCurrentAutomix, setCurrentPlayAutomix,
  setStateAutomix, setUidAutomix, setSettingHideAutomix, setCurrentIdAutomix,
  setRandomAutomix
} from "./automix/redux/actions/index";

function Root() {
  const [visible, setVisible] = useState(false);

  window.DJAPPMM = {
    loadsong_request: (pid, isFirst = false) => {
      // console.log('loadsong_request', pid);
      const state = store.getState();
      state.storeAutomix.load(pid, isFirst);
    },
    automix_off: () => {
      const state = store.getState();
      state.storeAutomix.disable();
    },
    automix_state: (state) =>{
      // console.log('automix_state', state);
      // if (!state) {
      //   store.dispatch(setUidAutomix(null));
      //   store.dispatch(setCurrentIdAutomix(null));
      // }
    },
    automix_shuffle: (_enable = null) => {
      if (_enable === null) {
        const state = store.getState();
        return state.isRandomAutomix;
      } else {
        store.dispatch(setRandomAutomix(_enable));
      }
    },
    automix_hide: (status) => {
      store.dispatch(setSettingHideAutomix(status));
    },
    automix_info: (data) => {
      // console.log('automix_info', data);
      const state = store.getState();
      if (state.currentIdAutomix !== data.currentid) {
        store.dispatch(setCurrentIdAutomix(data.currentid));
      }
      if (state.autoBpmAutomix !== data.autobpm) { store.dispatch(setAutoBpmAutomix(data.autobpm)); }
      if (state.bpmAutomix !== data.bpm) { store.dispatch(setBpmAutomix((Math.round(data.bpm * 10) / 10))); }
      if (state.stateAutomix !== data.state) {
        store.dispatch(setStateAutomix(data.state));
      }
      if (state.isCurrentPlayAutomix !== data.playing) { store.dispatch(setCurrentPlayAutomix(data.playing)); }
      if ((data.position - state.currentAutomix.position > 1) || (data.position - state.currentAutomix.position < -1) || (data.state === 'mixing' && data.mixingprogress > 1)) {
        store.dispatch(setCurrentAutomix(data));
      }
    }
  }

  const togglePlayer = () => {
    setVisible(!visible);
  }

  return (
    <Provider store={store}>
      <div className={'flexColumn center'} style={{alignItems: 'center'}}>
        <Player visible={visible} height={350} />
        <AutomixWrapper />
        <CoverFlowWrapper maxWidth={'100%'} height={400} isFullScreen={false} onFullScreen={togglePlayer} />
      </div>
    </Provider>
  )
}

export default Root;
