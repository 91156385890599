import React from 'react';
import { connect } from "react-redux";

function ConnectedProgress({ currentAutomix }) {
  console.log('COUCO', currentAutomix)
  return (
    <span className={`cf-button titlex start type-small type-light`} >
      {`Transition in progress ${currentAutomix.mixingprogress ? `${Math.round(currentAutomix.mixingprogress)}%`: `0%`}`}
    </span>
  )
}

const Progress = connect(
  state => ({
    currentAutomix: state.currentAutomix,
  })
)(ConnectedProgress);

export default Progress;
