import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

function ConnectedWaveform({ id, index, src, smooth = false, duration, onNext, active, selected, currentAutomix }) {
  const [dimension, setDimension] = useState(null);
  const [context, setContext] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [lock, setLock] = useState(false);

  const draw = useCallback((img) => () => {
    let canvas = document.getElementById(`wc${index}`);
    if (canvas) {
      let ctx = canvas.getContext("2d");
      const dpi = window.devicePixelRatio;
      ctx.scale(dpi, dpi);
      ctx.imageSmoothingQuality = `high`;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);

      ctx.globalCompositeOperation = "source-atop";
      ctx.fillStyle = `#525252`;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      setContext({ ctx, canvas, dpi })
    }
  }, [index]);

  const seek = () => {
    // TODO - BACK
    // if (cursorPosition !== null && parseInt(currentAutomix.currentid) === parseInt(id)) {
    //   const pourcent = (cursorPosition + 1) / dimension.width;
    //   window['YouDJ'] && window['YouDJ'].automix_seek(pourcent);
    // }
  };
  useEffect(() => { // Hover SVG
    if (dimension && active) {
      document.getElementById(`icf-seek`).addEventListener(`mouseleave`, (e) => {
        setCursorPosition(null);
      });
      document.getElementById(`icf-seek`).addEventListener(`mouseenter`, (e) => {
        const x = e.offsetX / window[`window_zoom`];
        setCursorPosition(x);
      });
      document.getElementById(`icf-seek`).addEventListener(`mousemove`, (e) => {
        const x = e.offsetX / window[`window_zoom`];
        if (x > 10) { setCursorPosition(x); }
      })
    }
  }, [dimension, active])

  useEffect(() => {
    if (!dimension) {
      setTimeout(() => {
        const el = document.getElementById(`block${index}`);
        if (el) {
          setDimension({ width: (el.getAttribute(`basewidth`) * 2) , height: Math.round(el.getAttribute(`basewidth`) * 0.35) })
        }
      }, 100)
    } else {
      var img = new Image();
      img.onload = draw(img);
      if (src) {
        img.src = src.replace(`1500x250`, `${dimension.width}x${dimension.height}`);
      }
    }
  }, [dimension, draw, index, src])

  useEffect( () => {
    if (context) {
      const { ctx, canvas } = context;

      if (parseInt(currentAutomix.currentid) === parseInt(id)) {
        const part = Math.round((currentAutomix.position * dimension.width) / duration);
        const outro = Math.round(((duration - currentAutomix.outro) * dimension.width) / duration) - 1; // ANTICIPATE THE TRANSITION 2s FOR PERFORMANCE

        if (currentAutomix.state === 'mixing' && currentAutomix.mixingpos > 5 && selected && !lock) {
          setLock(true);
          onNext();
        }

        if (selected) {
          if (part < outro) {
            ctx.fillStyle = `#4EDF84`; //Config.BRAND_PRIMARY;
            ctx.fillRect(0, 0, part, canvas.height);
            ctx.fillStyle = `#525252`; //Config.BLACK_5;
            ctx.fillRect(part, 0, outro, canvas.height);
            ctx.fillStyle = `#FFFFFF`;
            ctx.fillRect(outro, 0, outro + 1, canvas.height);
            ctx.fillStyle = `#525252`; // Config.BLACK_5;
            ctx.fillRect(outro + 1, 0, dimension.width - outro + 1, canvas.height);
          } else {
            ctx.fillStyle = `#4EDF84`; //Config.BRAND_PRIMARY;
            ctx.fillRect(0, 0, part, canvas.height);
            ctx.fillStyle = `#525252`; //Config.BLACK_5;
            ctx.fillRect(part, 0, dimension.width - part, canvas.height);
          }
        }
      } else if (currentAutomix.mixingid === id && selected) {
        const part = Math.round((currentAutomix.mixingpos * dimension.width) / duration);
        ctx.fillStyle = `#4EDF84`; //Config.BRAND_PRIMARY;
        ctx.fillRect(0, 0, part, canvas.height);;
        ctx.fillStyle = `#525252`; //Config.BLACK_5;
        ctx.fillRect(part, 0, dimension.width - part, canvas.height);
      }
    }
  }, [id, context, duration, dimension, currentAutomix, selected, lock, onNext])

  const dpi = window.devicePixelRatio;
  return (dimension && src) ? (
    <>
      <canvas id={`wc${index}`} width={dimension.width * dpi} height={dimension.height * dpi} style={{width: dimension.width, height: dimension.height}} />
      { active && (
        <div id={`icf-seek`} className={`cf-seek`} onClick={seek} style={{bottom: dimension.height / 2, height: dimension.height / 2}}>
          <svg id="jump_cf_hover" width="4" height={dimension.height / 2} style={{ display: (cursorPosition !== null && parseInt(currentAutomix.currentid) === parseInt(id)) ? `block` : `none`, position: `relative`, left: 0, transform: `translate3d(${cursorPosition}px, 0px, 0px)`}}>
            <path stroke="#FFF8" strokeWidth="1" d={`M1,0 v${dimension.height / 2}`}></path>
          </svg>
        </div>
      ) }
    </>
  ) : null;
}

ConnectedWaveform.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  duration: PropTypes.any.isRequired,
  smooth: PropTypes.bool,
  onNext: PropTypes.func,
  active: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired
}

const Waveform = connect(
  state => ({
    currentAutomix: state.currentAutomix,
  })
)(ConnectedWaveform);

export default Waveform;
