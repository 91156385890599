import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import IMixPlay from './svg/IMixPlay';
import IMixPause from './svg/IMixPause';

// TODO - THINK REWORK
// MERGE COVERFLOW + Automix ?
// OR SEPARATE CORRECTLY BOTH ?

function ConnectedPlayButton({ onPlay, onPause, isCurrentPlayAutomix }) {
  return (isCurrentPlayAutomix)
    ? (
      <div className={`flexRow bp-button medium primary type-small cf-command`} onClick={onPause} >
        <IMixPause size={20} color={`#0D0D0D`} className={`cf-icon`} /> Pause
      </div>
    )
    : (
      <div className={`flexRow bp-button medium primary type-small cf-command`} onClick={onPlay} >
        <IMixPlay size={20} color={`#0D0D0D`} className={`cf-icon`} /> Play
      </div>
    )
}

ConnectedPlayButton.propTypes = {
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  isCurrentPlayAutomix: PropTypes.bool,
}

const PlayButton = connect(
  state => ({
    isCurrentPlayAutomix: state.isCurrentPlayAutomix,
  })
)(ConnectedPlayButton);

export default PlayButton;
