// Redux Actions
import {
  SET_ENABLED_AUTOMIX,
  SET_STORE_AUTOMIX,
  SET_PLAYLIST_AUTOMIX,
  SET_STACK_AUTOMIX,
  SET_UID_AUTOMIX,
  LOAD_INDEX_AUTOMIX,
  SET_RANDOM_AUTOMIX,
  SET_PLAY_AUTOMIX,
  SET_AUTOBPM_AUTOMIX,
  SET_BPM_AUTOMIX,
  SET_CURRENT_PLAY_AUTOMIX,
  SET_CURRENT_AUTOMIX,
  SET_STATE_AUTOMIX,
  SET_SETTING_HIDE_AUTOMIX,
  SET_CURRENTID_AUTOMIX,
  SET_QUEUE_AUTOMIX
} from "../types";

export function enableAutomix(payload) {
  return { type: SET_ENABLED_AUTOMIX, payload }
};

export function setStoreAutomix(payload) {
  return { type: SET_STORE_AUTOMIX, payload }
};

export function setPlaylistAutomix(payload) {
  return { type: SET_PLAYLIST_AUTOMIX, payload }
};

export function setStackAutomix(payload) {
  return { type: SET_STACK_AUTOMIX, payload }
};

export function setUidAutomix(payload) {
  return { type: SET_UID_AUTOMIX, payload }
}

export function loadIndexAutomix(payload) {
  return { type: LOAD_INDEX_AUTOMIX, payload }
}

export function setRandomAutomix(payload) {
  return { type: SET_RANDOM_AUTOMIX, payload }
}

export function setNextInQueueAutomix(payload) {
  return { type: SET_QUEUE_AUTOMIX, payload }
}

export function setPlayAutomix(payload) {
  return { type: SET_PLAY_AUTOMIX, payload }
}

export function setAutoBpmAutomix(payload) {
  return { type: SET_AUTOBPM_AUTOMIX, payload }
}

export function setBpmAutomix(payload) {
  return { type: SET_BPM_AUTOMIX, payload }
}

export function setCurrentPlayAutomix(payload) {
  return { type: SET_CURRENT_PLAY_AUTOMIX, payload }
}

export function setCurrentAutomix(payload) {
  return { type: SET_CURRENT_AUTOMIX, payload }
}

export function setStateAutomix(payload) {
  return { type: SET_STATE_AUTOMIX, payload }
}

export function setSettingHideAutomix(payload) {
  return { type: SET_SETTING_HIDE_AUTOMIX, payload }
}

export function setCurrentIdAutomix(payload) {
  return { type: SET_CURRENTID_AUTOMIX, payload }
}
