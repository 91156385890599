import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

function ConnectedDuration({ id, currentAutomix }) {
  const [time, setTime] = useState(0);

  useEffect( () => {
    if (parseInt(currentAutomix.currentid) === parseInt(id)) {
      setTime(currentAutomix.position);
    } else if (parseInt(currentAutomix.mixingid) === parseInt(id)) {
      setTime(currentAutomix.mixingpos);
    }
  }, [id, currentAutomix])

  const format = (time) => {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return `${minutes}:${seconds}`;
  }

  return <span>{format(time)}</span>
}

ConnectedDuration.propTypes = {
  id: PropTypes.number.isRequired,
  currentAutomix: PropTypes.any.isRequired,
}

const Duration = connect(
  state => ({
    currentAutomix: state.currentAutomix,
  })
)(ConnectedDuration);

export default Duration;
