import React from 'react';

function Player2b() {
  return (
    <div id="player2b" style={{"position":"absolute","top":"100px","left":"949px","width":"450px","height":"212px","zIndex":"2"}}>
      <div id="vinyl2" style={{"position":"absolute","top":"7px","left":"158px"}}>
        <div id="vinyl2_center"  style={{"position":"absolute","top":"0px","left":"0px","border":"solid 10px #171719","background":"#09090B","borderRadius":"50%","width":"126px","height":"126px"}}>
          <img alt="" style={{"position":"absolute","top":"0px","left":"0px","background":"#050505","clipPath":"circle(63px at center)"}} src="/picx_bp/vinyl_default2.png" width="126" height="126" />
        </div>
        <div id="vinyl2_area" style={{"position":"absolute","top":"-9px","left":"-14px","cursor":"grab","borderRadius":"50%","width":"164px","height":"157px"}} />
        <div id="vinyl2_dot" style={{"position":"absolute","top":"0px","left":"71px","width":"2px","height":"10px","background":"#FFF","transformOrigin":"2px 73px"}} />
      </div>
      <div id="backspin2" className="backspin" style={{"position":"absolute","top":"49px","left":"199.5px","width":"62px","height":"62px","borderRadius":"50%"}} />
      <div id="bbox2" style={{"position":"absolute","top":"5px","left":"406px","width":"46px","height":"43px","background":"#171719"}} />
      <div id="bpm2" style={{"position":"absolute","top":"9px","left":"406px","width":"46px","fontSize":"14px","textAlign":"center","textRendering":"optimizeSpeed"}}>120.0</div>
      <div id="rate2" style={{"position":"absolute","top":"27px","left":"406px","width":"46px","fontSize":"12px","textAlign":"center","color":"#999","wordSpacing":"-2px","textRendering":"optimizeSpeed"}}>&nbsp;0.0 %</div>
      <div id="pro2_pitch_bend-" style={{"position":"absolute","left":"406px","top":"74px","width":"22.5px"}} className="butpro butpro2">
        <svg id="p2_bend_minus_touch" style={{display: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.20001 8H12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
        <svg id="p2_bend_minus" style={{"position":"absolute","top":"4px","left":"5.5px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 8 1 L 2 6 L 8 11"/>
        </svg>
      </div>
      <div id="pro2_pitch_bend+" style={{"position":"absolute","left":"430px","top":"74px","width":"22.5px"}} className="butpro butpro2">
        <svg id="p2_bend_plus_touch" style={{display: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 3.19995V12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
          <path d="M3.20001 8H12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
        <svg id="p2_bend_plus" style={{"position":"absolute","top":"4px","left":"6px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 2 1 L 8 6 L 2 11"/>
        </svg>
      </div>
      <div id="pro2_pitch_inc-" style={{"position":"absolute","left":"406px","top":"51px","width":"22.5px"}} className="butpro butpro2">
        <svg style={{"position":"absolute","top":"9.5px","left":"6.5px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 1 1 L 9 1"/>
        </svg>
      </div>
      <div id="pro2_pitch_inc+" style={{"position":"absolute","left":"430px","top":"51px","width":"22.5px"}} className="butpro butpro2">
        <svg style={{"position":"absolute","top":"5.5px","left":"6px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 5 1 L 5 9 M 1 5 L 9 5"/>
        </svg>
      </div>
      <div id="pro2_hotcue1" style={{"position":"absolute","left":"324px","top":"5px"}}  className="butpro nicefont">1</div>
      <div id="pro2_hotcue2" style={{"position":"absolute","left":"324px","top":"28px"}}   className="butpro nicefont">2</div>
      <div id="pro2_hotcue3" style={{"position":"absolute","left":"324px","top":"51px"}}   className="butpro nicefont">3</div>
      <div id="pro2_hotcue4" style={{"position":"absolute","left":"324px","top":"74px"}}   className="butpro nicefont">4</div>
      <div id="pro2_hotcue5" style={{"position":"absolute","left":"361px","top":"5px"}}  className="butpro nicefont">5</div>
      <div id="pro2_hotcue6" style={{"position":"absolute","left":"361px","top":"28px"}}  className="butpro nicefont">6</div>
      <div id="pro2_hotcue7" style={{"position":"absolute","left":"361px","top":"51px"}}  className="butpro nicefont">7</div>
      <div id="pro2_hotcue8" style={{"position":"absolute","left":"361px","top":"74px"}}  className="butpro nicefont">8</div>
      <div id="pro2_hotcue1_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue2_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue3_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue4_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue5_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue6_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue7_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>
      <div id="pro2_hotcue8_del" style={{"display":"none","position":"absolute","left":"0px","top":"0px"}}></div>

      <div id="pro2_jump-" style={{"position":"absolute","left":"324px","top":"102px","width":"23px"}} className="butpro butpro2">
        <svg style={{"position":"absolute","top":"4px","left":"5.5px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 8 1 L 2 6 L 8 11"/>
        </svg>
      </div>
      <div id="pro2_jump+" style={{"position":"absolute","left":"372px","top":"102px","width":"23px"}} className="butpro butpro2">
        <svg style={{"position":"absolute","top":"4px","left":"6px"}} width="12" height="12">
          <path fill="none" strokeWidth="1.2" d="M 2 1 L 8 6 L 2 11"/>
        </svg>
      </div>
      <div id="pro2_jump" style={{"position":"absolute","left":"348px","top":"102px","width":"23px"}} className="butpro butpro2 nicefont">8</div>
      <div id="sfx2_toggle" className="button nicefont" style={{"height":"30px","lineHeight":"30px","width":"71px","position":"absolute","top":"130px","left":"324px"}}>FX
        <div style={{"position":"absolute","top":"-2px","left":"-3px","borderTopRightRadius":"8px","height":"38px","width":"37px"}}></div>
      </div>
      <div id="loop2" style={{"position":"absolute","top":"168px","left":"306px"}}>
        <div className="button nicefont" id="loopB2" style={{"position":"absolute","top":"0px","left":"18px","width":"52px","textAlign":"center"}}>
          <svg style={{"position":"relative","verticalAlign":"-5px","display":"inline-block"}} width="26" height="18">
            <path id="loopX2" style={{"stroke":"#FFF"}} fill="none" strokeWidth="1" d="M16,3 h3 a2,2 0 0 1 2,2 v6 a2,2 0 0 1 -2,2 h-10 a2,2 0 0 1 -2,-2 v-6 a2,2 0 0 1 2,-2 h3" />
            <path id="loopY2" style={{fill: '#FFF'}} strokeWidth="0" d="M10.5,0.5 L10.5,6 L14,3 Z" />
          </svg>
          <span style={{"position":"relative","left":"-4px","fontSize":"14px","fontFamily":"Arial"}} id="loop_value2">4</span>
        </div>
        <div className="button button3b" id="loopC2" style={{"position":"absolute","top":"0px","left":"71px","width":"18px","height":"18.5px","stroke":"#FFF","borderRadius":"0 1px 0 0"}}>
          <svg id="loopC2_plus_touch" style={{display: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 3.19995V12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M3.20001 8H12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
          </svg>
          <svg id="loopC2_plus" style={{"position":"absolute","top":"7px","left":"4px"}} width="12" height="12">
            <path fill="none" strokeWidth="1.2" d="M 1 5 L 5 1 L 9 5"/>
          </svg>
        </div>
        <div className="button button3b" id="loopA2" style={{"position":"absolute","top":"19.5px","left":"71px","width":"18px","height":"18.5px","stroke":"#FFF","borderRadius":"0 0 1px 0"}}>
          <svg id="loopA2_minus_touch" style={{display: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.20001 8H12.8" stroke="#F3F3F3" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round"/>
          </svg>
          <svg id="loopA2_minus" style={{"position":"absolute","top":"6px","left":"4px"}} width="12" height="12">
            <path fill="none" strokeWidth="1.2" d="M 1 1 L 5 5 L 9 1"/>
          </svg>
        </div>
      </div>

      <div className="pro_board_clip" style={{"position":"absolute","left":"112px","top":"5px"}}>
        <div id="sfx2_board" className="pro_board" style={{"position":"absolute","left":"258px","top":"0px","background":"#0a0a0c"}}>
          <div id="sfx2_area" style={{"position":"absolute","top":"23px","left":"0px","width":"156px","height":"133px","background":"#171719"}}>
            <div style={{"position":"absolute","left":"76.5px","top":"0px","height":"133px","width":"0.5px","background":"#2A2A2C"}} />
            <div style={{"position":"absolute","left":"0px","top":"66px","height":"0.5px","width":"156px","background":"#2A2A2C"}} />
            <div id="sfx2_cursor_clip" className="pointer" style={{"position":"absolute","left":"0px","top":"0px","clip":"rect(0px,156px,133px,0px)","width":"156px","height":"133px"}}>
              <svg id="sfx2_cursor" height="29" width="29" style={{"position":"absolute","top":"0px","left":"0px","transform":"translate3d(0,0,0)","stroke":"#848484"}}>
                <path fill="none" strokeWidth="1" d="M0,14 L28,14 M14,0 L14,28" />
              </svg>
            </div>
          </div>
          <div id="sfx2_select" className="nicefont" style={{"display":"inline-block","height":"24px","lineHeight":"23px","padding":"0 14px 0 6px","cursor":"pointer","fontSize":"15px"}} />
          <div id="sfx2_lock" className="sfx_lock" style={{"position":"absolute","top":"5px","cursor":"pointer","left":"137px","width":"30px","height":"24px"}}>
            <svg height="14" width="13.5" style={{"position":"absolute","left":"0px","top":"-1px"}} viewBox="0 0 448 512">
              <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
            </svg>
          </div>
          <div id="sfx2_panel" className="nicefont" style={{"display":"none","position":"absolute","top":"0px","left":"0px"}} />
        </div>
      </div>

      <div id="eq23" style={{"position":"absolute","left":"64px","top":"160px"}} className="eq eqb">
        <span className="eqc"></span>
      </div>
      <div id="kill23" style={{"position":"absolute","left":"65px","top":"192px"}} className="eqd nicefont">
        <span></span>filter
      </div>
      <div id="eq21" style={{"position":"absolute","left":"64px","top":"54px"}} className="eq eqb">
        <span className="eqc"></span>
      </div>
      <div id="kill21" style={{"position":"absolute","left":"65px","top":"85px"}} className="eqd nicefont">
        <span></span>&nbsp;mid
      </div>
      <div id="eq22" style={{"position":"absolute","left":"64px","top":"107px"}} className="eq eqb">
        <span className="eqc"></span>
      </div>
      <div id="kill22" style={{"position":"absolute","left":"65px","top":"138px"}} className="eqd nicefont">
        <span></span>&nbsp;low
      </div>
      <div id="eq24" style={{"position":"absolute","left":"64px","top":"1px"}} className="eq eqb">
        <span className="eqc"></span>
      </div>
      <div id="kill24" style={{"position":"absolute","left":"65px","top":"32px"}} className="eqd nicefont">
        <span></span>high
      </div>
      <div id="eq25" style={{"position":"absolute","left":"18px","top":"1px"}} className="eq eqb">
        <span className="eqc"></span>
      </div>
      <div id="gain2" style={{"position":"absolute","left":"27px","top":"32px"}} className="eqd nicefont">gain</div>

      <div id="vumeter-container2" style={{"position":"absolute","top":"5px","left":"120px"}}>
        <svg style={{"position":"absolute","top":"8px","left":"0px","fill":"#050505"}} height="192" width="8">
          <rect x="1" y="0" width="6" height="192"/>
        </svg>
        <svg style={{"position":"absolute","top":"8px","left":"9px","fill":"#050505"}} height="192" width="8">
          <rect x="1" y="0" width="6" height="192"/>
        </svg>
        <svg id="peak2a" style={{"position":"absolute","top":"0px","left":"0px","fill":"#050505"}} height="8" width="8">
          <rect x="1" y="0" width="6" height="6"/>
        </svg>
        <svg id="peak2b" style={{"position":"absolute","top":"0px","left":"9px","fill":"#050505"}} height="8" width="8">
          <rect x="1" y="0" width="6" height="6"/>
        </svg>
        <svg id="vumeter2a" style={{"position":"absolute","top":"8px","left":"0px","clip":"rect(189px,8px,192px,0px)","fill":"#9B9B9B"}} height="192" width="8">
          <rect x="1" y="0" width="6" height="192"/>
        </svg>
        <svg id="vumeter2b" style={{"position":"absolute","top":"8px","left":"9px","clip":"rect(189px,8px,192px,0px)","fill":"#9B9B9B"}} height="192" width="8">
          <rect x="1" y="0" width="6" height="192"/>
        </svg>
      </div>

      <div id="fader2" className="pointer" style={{"position":"absolute","width":"50px","top":"65px","left":"19px"}}>
        <svg style={{"position":"absolute","top":"0px","left":"0px"}} height="145" width="40">
          <line x1="20" y1="2" x2="20" y2="137" strokeWidth="3" stroke="#000" />
        </svg>
        <div id="fader2_cursor" style={{"position":"absolute","top":"-1px","left":"5.5px","width":"29px","height":"16px","borderRadius":"1px","background":"#FFF","boxShadow":"#000 0px 0px 6px","transform":"translate3d(0px, -4px, 0px)"}} className="" />
      </div>

      <div id="pitch2" className="pointer" style={{"position":"absolute","width":"50px","top":"109px","left":"408px"}}>
        <svg style={{"position":"absolute","top":"1px","left":"38px"}} width="6.5" height="3">
          <path stroke="#555" fill="none" strokeWidth="1.2" d="M 0 1 L 6.5 1" />
        </svg>
        <svg style={{"position":"absolute","top":"87px","left":"37.5px"}} width="7" height="10">
          <path stroke="#555" fill="none" strokeWidth="1.2" d="M 3.5 1 L 3.5 8 M 0 4.5 L 7 4.5" />
        </svg>
        <svg style={{"position":"absolute","top":"0px","left":"0px"}} height="100" width="40">
          <line x1="12" y1="46" x2="28" y2="46" strokeWidth="2" stroke="#555"></line>
          <line x1="20" y1="1" x2="20" y2="94" strokeWidth="3" stroke="#000"></line>
        </svg>
        <div id="pitch2_cursor" className="pitch_cursor" style={{"position":"absolute","top":"45px","left":"6.5px","transform":"translate3d(0px, -7px, 0px)"}}>
          <svg style={{"position":"absolute","top":"6.5px","left":"4px"}} height="4" width="20">
            <line x1="1" y1="1" x2="18" y2="1" strokeWidth="3" stroke="#FFF"></line>
          </svg>
        </div>
      </div>
      <div id="cue2" className="button button3b nicefont" style={{"position":"absolute","top":"168px","left":"239px","fontSize":"15px","width":"72px"}}>Cue</div>
      <div id="play2" className="button" style={{"position":"absolute","top":"168px","left":"155px","width":"72px"}}>
        <div id="play2c" style={{"position":"absolute","top":"0px","left":"0px","width":"72px","height":"38px","background":"#3A3A3C","display":"none"}} />
        <div id="play2b1" style={{"position":"absolute","top":"0px","left":"0px","width":"72px","height":"38x","opacity":"0.6","clipPath":"inset(0 0 0 0)"}}>
          <div id="play2b2" style={{"position":"absolute","top":"0px","left":"0px","width":"142px","height":"40px"}} />
        </div>
        <svg id="play2a1" style={{"position":"absolute","top":"12px","left":"30px","fill":"#FFF","display":"block"}} width="13" height="17">
          <path d="M1,1 L1,14 L12,7.5 L1,1 Z" />
        </svg>
        <svg id="play2a2" style={{"position":"absolute","top":"12px","left":"30px","fill":"#000","display":"none"}} width="20" height="17">
          <path d="M1,14 L3.5,14 L3.5,1 L1,1 Z M7.5,1 L7.5,14 L10,14 L10,1 Z" />
        </svg>
      </div>
    </div>
  )
}

export default Player2b;
