import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import IMixPlay from './svg/IMixPlay';

function ConnectedLoadButton({ onClick, stateAutomix }) {
  return (stateAutomix !== 'mixing')
    ? (
      <div className={`flexRow bp-button medium command type-small cf-command`} onClick={onClick} >
        <IMixPlay size={20} color={`#8E8E93`} className={`cf-icon`} /> Mix Now
      </div>
    )
    : (
      <div className={`flexRow bp-button medium unavailable type-small cf-command`} >
        <IMixPlay size={20} color={`#8E8E93`} className={`cf-icon`} /> Mix Now
      </div>
    )
}

ConnectedLoadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  stateAutomix: PropTypes.string,
}

const LoadButton = connect(
  state => ({
    stateAutomix: state.stateAutomix,
  })
)(ConnectedLoadButton);

export default LoadButton;
