import React from 'react';
import PropTypes from 'prop-types';

function QueueButton({ onClick }) {
  return (
    <div className={`flexRow bp-button medium command type-small cf-command`} onClick={onClick} >
      Next in queue
    </div>
  )
}

QueueButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default QueueButton;
