import React from 'react';
import PropTypes from 'prop-types';

function IMixPlay({ size, color = `#E5E5E5`, className }) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M6.25 4L14.8214 10L6.25 16V4Z" fill={color} stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixPlay.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
}

export default IMixPlay;
