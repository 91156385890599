import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Radium, { StyleRoot } from 'radium';
import './Coverflow.css';

const TOUCH = {
  move: false,
  lastX: 0,
  sign: 0,
  lastMove: 0,
};

const TRANSITIONS = [
  'transitionend',
  'oTransitionEnd',
  'otransitionend',
  'MSTransitionEnd',
  'webkitTransitionEnd',
];

const CURRENT_FIGURE_SCALE = 1;
const PAD_RIGHT_LEFT = 190;
const SPACE_QUANTITY = 5;

const HandleAnimationState = function() {
  this._removePointerEvents();
};

class Coverflow extends Component {
  refNode = createRef();

  static propTypes = {
    children: PropTypes.node.isRequired,
    enableScroll: PropTypes.bool,
    clickable: PropTypes.bool,
    active: PropTypes.number,
    media: PropTypes.any,
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    enableScroll: true,
    clickable: true,
    classes: {},
    className: '',
    active: 0,
    media: {},
    width: 'auto',
    height: 'auto'
  };

  state = {
    current: 0,
    move: 0,
    width: this.props.width,
    height: this.props.height,
    baseWidth: 0,
    firstLoad: true
  };

  componentDidMount() {
    this.updateDimensions(this.props.active);
    const length = React.Children.count(this.props.children);

    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        const figureID = `figure_${i}`;
        this.refs[figureID].addEventListener(event, HandleAnimationState.bind(this));
      }
    });

    const eventListener = window && window.addEventListener;

    if (eventListener) {
      window.addEventListener('resize', this.updateDimensions.bind(this));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active || this.props.height !== prevProps.height) {
      if (this.state.firstLoad) { this.setState({firstLoad: false}) }
      this.updateDimensions(this.props.active, this.props.height);
    }
  }

  componentWillUnmount() {
    const length = React.Children.count(this.props.children);

    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        const figureID = `figure_${i}`;
        this.refs[figureID].removeEventListener(event, HandleAnimationState.bind(this));
      }
    });
  }

  updateDimensions(active, forceHeight = null) {
    const length = React.Children.count(this.props.children);
    const center = this._center();
    const width = (this.refNode.current) ? this.refNode.current.offsetWidth : 0;
    const height = (forceHeight !== null) ? forceHeight : ((this.refNode.current) ? this.refNode.current.offsetHeight : 0);

    const baseWidth = (width - (PAD_RIGHT_LEFT * 2)) / SPACE_QUANTITY;
    let state = { width, height, baseWidth };

    let activeImg = typeof active === 'number' ? active : this.props.active;
    if (typeof active === 'number' && ~~active < length) {
      activeImg = ~~active;
      let move = 0;
      move = baseWidth * (center - activeImg);

      state = Object.assign({}, state, {
        current: active,
        move,
      });
    }
    this.setState(state);
  }

  render() {
    const { enableScroll, className, classes, media } = this.props;
    const { width, height } = this.state;
    return (
      <div ref={this.refNode}>
        <StyleRoot>
          <div
            className={`${className} container`}
            style={
              Object.keys(media).length !== 0 ? media : { ...classes, width: `${width}px`, height: `${height}px` }
            }
            onWheel={enableScroll ? this._handleWheel.bind(this) : null}
            onTouchStart={this._handleTouchStart.bind(this)}
            onTouchMove={this._handleTouchMove.bind(this)}
            onKeyDown={this._keyDown.bind(this)}
            tabIndex="-1"
          >
            <div className={`coverflow`}>
              <div className={`preloader`} />
              <div className={`stage`} ref="stage">
                { this._renderFigureNodes() }
              </div>
              </div>
          </div>
        </StyleRoot>
      </div>
    );
  }

  /**
   * Private methods
   */
  _center() {
    const length = React.Children.count(this.props.children);
    return ~~(length / 2);
  }

  _keyDown(e) {
    if (e.keyCode === 37) {
      this._handlePrevFigure();
    } else if (e.keyCode === 39) {
      this._handleNextFigure();
    }
  }

  _handleFigureStyle(index, current) {
    const { width, baseWidth } = this.state;
    const style = {};
    const length = React.Children.count(this.props.children);
    const offset = length % 2 === 0 ? -width / 10 : 0;
    const rotate = 0;
    style.width = `${baseWidth}px`;

    // Handle translateX
    if (index === current) { // CURRENT ACTIVE
      style.width = `${baseWidth * 3}px`;
      style.transform = `translateX(${this.state.move + offset }px) scale(${CURRENT_FIGURE_SCALE}`;
      style.zIndex = length;
      style.opacity = 1;
    } else if (index < current) { // LEFT SIDE
      const leftDepth = current - index;
      let pourcentDepth = 1 - (leftDepth / 10) ;
      pourcentDepth = (pourcentDepth < 0.7) ? 0 : pourcentDepth;
      const offsetCustom = ((baseWidth * 0.25) * leftDepth) + ((baseWidth - (baseWidth * pourcentDepth)) * leftDepth);
      style.transform = `translateX(${this.state.move + offset + offsetCustom }px) rotateY(${rotate}deg) scale(${pourcentDepth}`; // 1 //this.props.otherFigureScale - DEFAULT
      style.zIndex = length - leftDepth;
      style.opacity = pourcentDepth;
    } else if (index > current) { // RIGHT SIDE
      const rightDepth = index - current;
      let pourcentDepth = 1 - (rightDepth / 10);
      pourcentDepth = (pourcentDepth < 0.7) ? 0 : pourcentDepth;

      const offsetCustom = ((baseWidth * 0.25) * rightDepth) + ((baseWidth - (baseWidth * pourcentDepth)) * rightDepth);

      style.transform = ` translateX(${this.state.move + offset - offsetCustom }px) rotateY(${rotate}deg) scale(${pourcentDepth})`; //this.props.otherFigureScale - DEFAULT
      style.zIndex = length - rightDepth;
      style.opacity = pourcentDepth;
    }
    return style;
  }

  _handleFigureClick = (index, action, e) => {
    if (!this.props.clickable) {
      e.preventDefault();
      return;
    }
    if (this.state.current === index) {
      // If on the active figure
      if (typeof action === 'string') {
        // If action is a URL (string), follow the link
        window.open(action, '_blank');
      }

      this._removePointerEvents();
    } else {
      // Move to the selected figure
      e.preventDefault();
      const { baseWidth } = this.state;
      const distance = this._center() - index;
      const move = distance * baseWidth;
      this.setState({ current: index, move });
    }
  };

  _renderFigureNodes = () => {
    const { current, baseWidth, firstLoad } = this.state;
    const figureNodes = React.Children.map(this.props.children, (child, index) => {
      const figureElement = React.cloneElement(child, {
        id: `block${index}`,
        className: `block ${index === current && `active`} flexRow`,
        basewidth: Math.round(baseWidth)
      });
      const style = this._handleFigureStyle(index, current);
      const isFast = (current - index > 2 || current - index < -2);
      return (
        <figure
          className={`figure ${!firstLoad && `animate`} ${(!firstLoad && isFast) && `fast`} `}
          key={index}
          onClick={e => this._handleFigureClick(index, figureElement.props['data-action'], e)}
          style={style}
          ref={`figure_${index}`}
        >
          {figureElement}
        </figure>
      );
    });
    return figureNodes;
  };

  _removePointerEvents() {
    this.refs.stage.style.pointerEvents = 'auto';
  }

  _hasPrevFigure = () => this.state.current - 1 >= 0;

  _hasNextFigure = () => this.state.current + 1 < this.props.children.length;

  _handlePrevFigure = (e) => {
    const { baseWidth } = this.state;
    const { current } = this.state;
    const distance =
      this._center() - (current - 1 < 0 ? this.props.children.length - 1 : current - 1);
    const move = distance * baseWidth;

    if (current - 1 >= 0) {
      this.setState({ current: current - 1, move });
      TOUCH.lastMove = move;
    }
  };

  _handleNextFigure = (e) => {
    const { baseWidth, current } = this.state;
    const distance = this._center() - (current + 1 >= this.props.children.length ? 0 : current + 1);
    const move = distance * baseWidth;

    if (current + 1 < this.props.children.length) {
      this.setState({ current: current + 1, move });
      TOUCH.lastMove = move;
    }
  };

  _handleWheel(e) {
    const delta = Math.abs(e.deltaY) === 125 ? e.deltaY * -120 : e.deltaY < 0 ? -600000 : 600000;
    const count = Math.ceil(Math.abs(delta) / 120);

    if (count > 0) {
      const sign = Math.abs(delta) / delta;
      let func = null;

      if (sign > 0 && this._hasPrevFigure()) {
        e.preventDefault();
        func = this._handlePrevFigure();
      } else if (sign < 0 && this._hasNextFigure()) {
        e.preventDefault();
        func = this._handleNextFigure();
      }

      if (typeof func === 'function') {
        for (let i = 0; i < count; i++) func();
      }
    }
  }

  _handleTouchStart(e) {
    TOUCH.lastX = e.nativeEvent.touches[0].clientX;
    TOUCH.lastMove = this.state.move;
  }

  _handleTouchMove(e) {
    e.preventDefault();
    const { baseWidth } = this.state;

    const clientX = e.nativeEvent.touches[0].clientX;
    const lastX = TOUCH.lastX;
    const move = clientX - lastX;
    const totalMove = TOUCH.lastMove - move;
    const sign = Math.abs(move) / move;

    if (Math.abs(totalMove) >= baseWidth) {
      let fn = null;
      if (sign > 0) {
        fn = this._handlePrevFigure();
      } else if (sign < 0) {
        fn = this._handleNextFigure();
      }
      if (typeof fn === 'function') {
        fn();
      }
    }
  }
}

Coverflow.displayName = 'Coverflow';

export default Radium(Coverflow);
