import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import './CoverflowBase.css'

import Coverflow from './Coverflow';
import State from './State';
import PlayButton from './PlayButton';
import SkipButton from './SkipButton';
import LoadButton from './LoadButton';
import QueueButton from './QueueButton';
import Duration from './Duration';
import Waveform from './Waveform';
import WaveformIdle from './WaveformIdle';
import InputBPM from './InputBPM';
import Emergency from './Emergency';
// import ShortcutsWrapper from './ShortcutsWrapper';

import IMixTitle from './svg/IMixTitle';
import IMixToggle from './svg/IMixToggle';
import IMixRandom from './svg/IMixRandom';
import IMixBPM from './svg/IMixBPM';
import IMixAdd from './svg/IMixAdd';
import IMixSub from './svg/IMixSub';
import ILock from './svg/ILock';
import IMixFullScreen from './svg/IMixFullScreen';

import {
  loadIndexAutomix, setRandomAutomix, setPlayAutomix, setNextInQueueAutomix
} from "../automix/redux/actions/index";

function ConnectedCoverFlow({
  maxWidth, height, style, onFullScreen, isFullScreen = false,
  uidAutomix, isAutomix, playlists, isRandom, loadIndexAutomix, setRandomAutomix,
  setPlayAutomix, isPlay, bpmAutomix, autoBpmAutomix, isSettingHideAutomix, setNextInQueueAutomix
}) {
  // const { playerCoordinates } = dimension;
  const coverFlowStyle = { width: maxWidth, height, marginLeft: 0 };
  const [active, setActive] = useState(null);
  const [uid, setUid] = useState(uidAutomix);

  // if (playerCoordinates && height > 150) {
  //   document.getElementById(playerCoordinates.playerID).style[`z-index`] = (isAutomix && !isSettingHideAutomix) ? 0 : 40;
  //   // Performance CPU USED 80% -> 15%
  //   document.getElementById(`player`).style.display = (isAutomix && !isSettingHideAutomix) ? `none` : `initial`;
  // } else if (playerCoordinates && height < 150) { // Switching to & from Compact view is causing the decks to disappear for some users
  //   const playerEl = document.getElementById(`player`);
  //   if(playerEl && playerEl.style.display === `none`) {
  //     document.getElementById(`player`).style.display = `initial`;
  //   }
  // }

  useEffect(() => {
    // console.log(`**COVERFLOW**`, playlists.active, active)
    if (isAutomix && playlists && playlists.active !== undefined) {
      setTimeout(() => {
        if (uidAutomix !== uid) { // Each new playlist load
          setActive(0);
          setUid(uidAutomix);
        } else if (isAutomix && playlists.active === 0) { // Toggle on the same playlist
          setActive(0);
        }
      }, 300)
    } else {
      setActive(null);
    }
  }, [isAutomix, uidAutomix, uid, playlists /*playlists.active*/])

  const toggle = () => {
    // window['YouDJ'] && window['YouDJ'].automix_toggle(uidAutomix);
    onFullScreen(null, false);
  }

  const autoBPM = () => {
    window['YouDJ'] && window['YouDJ'].automix_autobpm(!autoBpmAutomix);
  }
  const setBPM = (val) => (isDown) => {
    window['YouDJ'] && window['YouDJ'].automix_bpm_change(val, isDown);
  }

  const callPlay = () => {
    window['YouDJ'] && window['YouDJ'].automix_playpause();
    setPlayAutomix(true);
  }

  const callPause = () => {
    window['YouDJ'] && window['YouDJ'].automix_playpause();
    setPlayAutomix(false);
  }

  const callNext = () => {
    window['YouDJ'] && window['YouDJ'].automix_next();
  }

  const prepareNext = (isMixing = false) => () => {
    if (isMixing) {
      setTimeout(() => { callNext() }, 10) // Long Transition : possible to quickly cut the transition
    } else {
      setActive(active + 1);
      setTimeout(() => { callNext() }, 1400)
    }
  }

  const callLoad = () => { // LOAD THE SELECTED AND NOT THE NEXT
    if (active !== null) {
      setActive(active);
      loadIndexAutomix(active);
      setTimeout(() => { callNext() }, 100)
    }
  }

  // const preparePrevious = () => {
  //   if (active > 0) {
  //     setActive(active - 1);
  //     loadIndexAutomix(active - 1);
  //     setTimeout(() => { callNext() }, 500)
  //   }
  // }

  const toggleRandom = () => { // Shuffle only next after active
    setRandomAutomix(!isRandom);
  }

  const nextInQueue = (index) => () => {
    setNextInQueueAutomix(index);
    setActive(playlists.active + 1);
  }

  const clickItem = (i) => () => setActive(i);
  const resize = (url) => url.replace(`105x105`, `210x210`); // DEPRECATED - IMG Default 250x250

  return (isAutomix) ? (
    <div className={`coverflow ${isAutomix && height > 150 ? `on` : `off`}`} style={coverFlowStyle} >
      {(isAutomix && playlists.tracks.length > 0 && height > 150) && (
        <>
          <div className={`flexRow space-between cf-bar`} style={{top: height - 40}}>
            <IMixTitle className={`i-mix-title`} />
            <div className={`flexRow cf-commands`}>
              <PlayButton onPlay={callPlay} onPause={callPause} />
              { (playlists.active === active)
                  ? <SkipButton onClick={prepareNext} />
                  : <LoadButton onClick={callLoad} />
              }
              { (playlists.active - active !== 0 && active - playlists.active !== 1 && active - playlists.active !== -1) && <QueueButton onClick={nextInQueue(active)} /> }
              <div className={`flexRow bp-button medium ${isRandom ? `primary` : `command`} type-small cf-command`} onClick={toggleRandom} >
                <IMixRandom size={20} color={isRandom ? `#0D0D0D` : '#8E8E93'} className={`cf-icon`} /> Shuffle
              </div>
              <div className={`flexRow bp-button medium ${autoBpmAutomix ? `primary` : `command`} type-small cf-command`} onClick={autoBPM} >
                <IMixBPM size={20} color={autoBpmAutomix ? `#0D0D0D` : '#8E8E93'} className={`cf-icon`} /> AutoBPM
              </div>
              <div className={`flexRow space-around bp-button medium infos type-small cf-command ${autoBpmAutomix && `noaction`} `} >
                {autoBpmAutomix && <ILock />}
                {!autoBpmAutomix && <IMixSub size={15} color={'#8E8E93'} className={`cf-icon left`} onClick={setBPM(-1)} />}
                <InputBPM base={bpmAutomix} lock={autoBpmAutomix} />
                {!autoBpmAutomix &&  <IMixAdd size={15} color={'#8E8E93'} className={`cf-icon right`} onClick={setBPM(1)} />}
              </div>
            </div>
            <IMixFullScreen size={20} className={`cf-fullscreen`} color={ isFullScreen ? `#4EDF84` : '#8E8E93' } onClick={onFullScreen} />
            <IMixToggle className={`automix-toggle`} onClick={toggle} />
          </div>
          <State active={playlists.active} tracks={playlists.tracks} width={maxWidth} onClick={clickItem} title={uidAutomix} to={playlists.from} />
          <Emergency selected={active} load={setActive} />
          { /* <ShortcutsWrapper onRandom={toggleRandom} onNext={prepareNext(false)} onPrevious={preparePrevious} /> */ }
          <Coverflow key={playlists.from} width={coverFlowStyle.width} height={height - 4} className={``}
            enableScroll={false}
            clickable={true}
            active={active}
          >
            { playlists.tracks.map( (track,i) => (
              <div key={`coverflow-${i}`} role="menuitem" style={{textAlign: `center`}} onClick={clickItem(i)} >
                <div className={`cf-cover`}>
                  <div className={`oo`} />
                  <img src={resize(track.img)} alt={'test'} className={`card-image`} style={{ width: `100%`, height: `100%` }} />
                  { /* active === i
                    ? <CoverLink to={`/releases/${track.releaseID}`} alt={`cover-${track.id}`} src={resize(track.img)} />
                    : <CoverSplit name={`cover-${track.id}`} images={[resize(track.img)]} size={`100%`} className={`display-block`} />
                    */
                  }
                </div>
                <div className={`flexColumn cf-infos`}>
                  <div className={`flexColumn cf-data`}>
                    <div className={`flexRow space-between type-micro`}>
                      <span className={`cf-state`}>{playlists.active === i && `Current Track`}&nbsp;</span>
                    </div>
                    <div className={`flexRow space-between type-regular`}>
                      <span className={`cf-title`}>{`${track.title} ${(track.mix_name !== '') ? ` - ${track.mix_name}` : null}`}</span>
                      <span className={`cf-key`}>{ track.key_camelot }</span>
                    </div>
                    <div className={`flexRow space-between type-small`}>
                      <div className={`cf-artists`}>
                        {track.artists.map( (a, i) => (
                          <span className={`link-enable`}>{a.name}</span>
                        ))}
                        {` • `}
                        <span className={`link-enable`}>{track.label}</span>
                        {` • `}
                        <span className={`link-enable`}>{track.genre_s}</span>
                      </div>
                      <div className={`cf-duration`}>
                        <span>{ playlists.active === i || active === i ? <Duration id={track.id} /> : `0:00` } / { track.length }</span>
                      </div>
                    </div>
                  </div>
                  <div className={`cf-waveform`}>
                    { playlists.active === i || active === i
                      ? <Waveform key={`w-${track.id}`} id={track.id} index={i} src={track.waveform} smooth={false}
                        active={(playlists.active === i)} selected={active === i} duration={track.length_s} onNext={clickItem(i + 1)} />
                      : <WaveformIdle key={`w-${track.id}`} id={track.id} index={i} src={track.waveform} smooth={false} duration={track.length_s} />
                    }
                  </div>
                </div>
              </div>
            ) ) }
          </Coverflow>
        </>
      )}

    </div>
  ) : <div className={`hide-coverflow`} style={coverFlowStyle} />
}

ConnectedCoverFlow.propTypes = {
  maxWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.object,
  onFullScreen: PropTypes.func,
  isFullScreen: PropTypes.bool,
}

const CoverFlow = connect(
  state => ({
    // dimension: state.dimension,
    uidAutomix: state.uidAutomix,
    isAutomix: !!state.uidAutomix,
    isRandom: state.isRandomAutomix,
    playlists: state.playlistAutomix,
    // isPlay: state.isPlayingAutomix,
    bpmAutomix: state.bpmAutomix,
    autoBpmAutomix: state.autoBpmAutomix,
    isSettingHideAutomix: false, //state.isSettingHideAutomix
  }),
  dispatch => ({
    loadIndexAutomix: index => dispatch(loadIndexAutomix(index)),
    setRandomAutomix: state => dispatch(setRandomAutomix(state)),
    setNextInQueueAutomix: index => dispatch(setNextInQueueAutomix(index)),
    setPlayAutomix: state => dispatch(setPlayAutomix(state))
  })
)(ConnectedCoverFlow);

export default CoverFlow;
