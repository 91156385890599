import React from 'react';
import PropTypes from 'prop-types';

function IMixRandom({ size, color = `#A0A0A0`, className }) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M12.4705 4H15.9999V7.33333" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M4 15.3333L16 4" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M15.9999 12.6667V16H12.4705" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M11.7646 11.9998L15.9999 15.9998" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M4 4.66661L7.52941 7.99994" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixRandom.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
}

export default IMixRandom;
