import React from 'react';

function Mixerb() {
  return (
    <div id="mixerb" style={{"position":"absolute","top":"100px","left":"462px","width":"486px","height":"212px","zIndex":"1"}}>
      <div className="button hpinactive" id="headphone1" onMouseDown={() => { /* headphone_button_toggle(1) */ }} style={{"position":"absolute","top":"178px","left":"0px","width":"29px","height":"28px","fill":"#999"}}>
        <svg style={{"position":"absolute","top":"4.5px","left":"4.5px"}} viewBox="0 0 24 24" width="20.5" height="20.5">
          <path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z"/>
        </svg>
      </div>
      <div className="button hpinactive" id="headphone2" onMouseDown={() => { /* headphone_button_toggle(2) */ }} style={{"position":"absolute","top":"178px","right":"0px","width":"29px","height":"28px","fill":"#999"}}>
        <svg style={{"position":"absolute","top":"4.5px","left":"4.5px"}} viewBox="0 0 24 24" width="20.5" height="20.5">
          <path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z"/>
        </svg>
      </div>
      <div style={{"position":"absolute","left":"-1px","top":"4px","width":"100%","height":"160px","border":"solid 0.5px #28282A"}} />
      <div id="wave_content" style={{"position":"absolute","left":"0px","top":"5px","clipPath":"inset(0% 0% 0% 0%)","width":"486px","height":"160px","background":"#171719"}}>
        <canvas id="wave_canvas1" style={{"position":"absolute","left":"0px","top":"0px"}} width="3000" height="80"></canvas>
        <canvas id="wave_canvas2" style={{"position":"absolute","left":"0px","top":"0px","clip":"rect(81px,3000px,160px,0px)"}} width="3000" height="160"></canvas>
        <div id="wave_line" style={{"position":"absolute","background":"#FFF","width":"2px","height":"160px","top":"0px","left":"242px"}}></div>
        <div style={{"position":"absolute","background":"#2A2A2A","width":"100%","height":"2px","top":"79px","left":"0px"}}></div>
        <div id="wave_area1" style={{"position":"absolute","width":"100%","height":"80px","top":"0px","left":"0px","cursor":"pointer"}}></div>
        <div id="wave_area2" style={{"position":"absolute","width":"100%","height":"80px","top":"80px","left":"0px","cursor":"pointer"}}></div>
        <div id="wave_zoom+" className="wavebutton" style={{"position":"absolute","top":"2px","right":"2px"}}>
          <svg style={{"position":"absolute","top":"4px","left":"3px"}} width="20" height="20">
            <path fill="none" strokeWidth="2" d="M 6.5 1 L 6.5 12 M 1 6.5 L 12 6.5"/>
          </svg>
        </div>
        <div id="wave_zoom-" className="wavebutton" style={{"position":"absolute","top":"2px","left":"2px"}}>
          <svg style={{"position":"absolute","top":"4px","left":"3px"}} width="20" height="20">
            <path fill="none" strokeWidth="2" d="M 1 6.5 L 12 6.5"/>
          </svg>
        </div>
        <div id="wave_edit1" onMouseDown={() => { /* editor(1,true) */ }} className="wavebutton" style={{"position":"absolute","bottom":"2px","left":"2px"}}>
          <svg style={{"position":"absolute","top":"4px","left":"3.5px"}} viewBox="0 0 512 512" height="12" width="12">
            <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path>
          </svg>
        </div>
        <div id="wave_edit2" onMouseDown={() => { /* editor(2,true) */ }} className="wavebutton" style={{"position":"absolute","bottom":"2px","right":"2px"}}>
          <svg style={{"position":"absolute","top":"4px","left":"3.5px"}} viewBox="0 0 512 512" height="12" width="12">
            <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"></path>
          </svg>
        </div>
        <div id="wave_editor1" className="editor_panel1" style={{"position":"absolute","top":"0px","left":"-150px"}}></div>
        <div id="wave_editor2" className="editor_panel2" style={{"position":"absolute","top":"0px","right":"-150px"}}></div>
      </div>
    </div>
  )
}

export default Mixerb;
