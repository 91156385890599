import React, { useEffect, useState } from 'react';

import Extra from './parts/Extra';
import Topbar from './parts/Topbar';
import Player1a from './parts/Player1a';
import Player1b from './parts/Player1b';
import Mixera from './parts/Mixera';
import Mixerb from './parts/Mixerb';
import Player2a from './parts/Player2a';
import Player2b from './parts/Player2b';
import Settings from './parts/Settings';

const ENV = 'dev';
// const ENV = 'min';

// import data from '../data';

let YouDJ = {};
function Player({ visible = true, height }) {
  const [isReady, setReady] = useState(false);
  useEffect(() => {

    let script = document.createElement('script');
    script.src = `https://playbeta.you.dj/static/dist/jquery-sdk-${ENV}.js?_=LLL`;
    script.onload = () => { setReady(true); }
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(script);

    let link = document.createElement('link');
    link.href = 'https://playbeta.you.dj/_style_bp.css?_=v76xx6';
    link.rel = "stylesheet";
    link.type = "text/css";
    body.appendChild(link);

  }, [])

  useEffect(() => {
    if (isReady) {

// TODO - Rattach automix Root

      YouDJ = {
      	open_shortcuts: function() {  },
      	tooltips_toggle: function(_enable) { },
      	loadsong_mp4: window.beatport_loadsong, // loadsong_mp4(_deckid:int, _url:string, _title:string, _tag:string, _duration:int, _songid:string, _image:string, _autoplay:bool)
      	player_set_compact: window.beatport_set_compact, // player_set_compact(_enable:bool)

      	automix_toggle: window.beatport_automix_toggle, // automix_toggle(_message:string)
      	automix_on: window.beatport_automix_on, // automix_on(_message:string)
      	automix_off: window.beatport_automix_off, // automix_off()
      	automix_next: window.beatport_automix_next, // automix_next()
      	automix_playpause: window.beatport_automix_playpause, // automix_playpause()
        automix_request: function(_deckid, _first) {
          // console.log('automix_request',_deckid, _first)
          window.DJAPPMM && window.DJAPPMM.loadsong_request(_deckid, _first);
        }, // automix_request(deckid:int, first:bool)
      	automix_state: function(_enable) {
          // console.log('automix_state',_enable)
          window.DJAPPMM && window.DJAPPMM.automix_state(_enable);
        }, // automix_state(enable:bool)
      	automix_info: function(_info) {
          // console.log('automix_info',_info)
          window.DJAPPMM && window.DJAPPMM.automix_info(_info);
        }, // automix_info(info:object) at 30FPS
      	automix_hide: function(_hide) {
          // console.log('automix_hide',_hide)
          if (!window.DJAPPMM.automix_hide) { setTimeout(() => { window.DJAPPMM.automix_hide(_hide); }, 2000); } else { window.DJAPPMM.automix_hide(_hide); }
        }, // automix_hide(hide:bool) // NOTE : from the settings
        automix_shuffle: function(_enable) {
          // console.log('automix_shuffle',_enable)
          if(window.DJAPPMM && window.DJAPPMM.automix_shuffle) return window.DJAPPMM.automix_shuffle(_enable);
        },
      	automix_seek: window.beatport_automix_seek, // automix_seek(_seek:number) NOTE : seek value is between 0 and 1 (not the position in second!)
      	automix_autobpm: window.beatport_automix_autobpm, // automix_autobpm(enable:bool) // NOTE : turn on/off the autobpm, automix_info gives the current autobpm state and the current bpm
      	automix_bpm_change: window.beatport_automix_bpm_change, // automix_bpm_change(_value:int,_down:bool) NOTE : to use only when autobpm is off! _value=1 > bpm +0.1, _value=-1 > bpm -0.1, _down=true > button mousedown, _down=false > button mouseup
      	automix_bpm_value: window.beatport_automix_bpm_value, // automix_bpm_value(_bpmvalue:number)  // NOTE :  to use only when autobpm is off! give the bpm value directly

        loadsong_reject: function(deckid, songid) { console.log("song rejected player",deckid,songid); },
        player_resize: window.beatport_player_resize,  // player_resize(zoom)
      	set_user: window.beatport_login, // *** DEPRECATED! use YouDJ.login instead ***
      	set_token: window.beatport_set_token,
      	track_back: function() {  },
      	track_load: function(_deckid) {  }, // track_load(deckid:int) deckid = 1 or 2
      	track_select: function(_direction) { }, // track_select(direction:int) direction = 1 or -1
      	login_command: function(_loggedin) {  },
      	login: window.beatport_login,  //  login(userid:string, username:string, imageurl:string)
      	logout: window.beatport_logout,  // logout()
      };
      window.YouDJ = YouDJ;

      const window_resize = () => {
        window.scrollTo(0,0);
        var window_width_current = document.documentElement.offsetWidth;
        var window_width_needed = 1410;
        const window_zoom = (window_width_current < window_width_needed ? window_width_current / window_width_needed : 1);
        document.body.style.zoom = (window_zoom !== 1 ? window_zoom : ""); // not supported in firefox
        YouDJ.player_resize(window_zoom);
      }
      window.onresize = window_resize;
      window_resize();
      window.document_ready();

      // TEST that it works
      // const track = data[0];
      // YouDJ.loadsong_mp4(track.pid, track.url, track.title, track.tag, track.keys, track.length, track.id, track.img, track.autoplay, track.start_s, track.end_s, track.isBroadcast);

    }
  }, [isReady])

  return (
    <div style={{opacity: visible ? 1 : 0, height }}>
      <Extra />
      <Topbar />
      <div id="alpha2" style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', left: 0, top:0, height:316, background: 'linear-gradient(#171719, #131315 30%)', boxShadow: 'inset 0px 0.5px rgba(255, 255, 255, 0.05)', zIndex: 50 }}>
        <div id="alpha2b" style={{"display":"block","position":"absolute","top":"95px","left":"0","width":"100%","height":"221px","boxShadow":"inset 0px 0.5px rgba(255, 255, 255, 0.04), inset 0px -0.5px rgba(255, 255, 255, 0.04)","background":"radial-gradient(circle at top center, #262628,  #242426)"}} />
        <div id="alpha2c" style={{"display":"none","position":"absolute","top":"94.5px","left":"0","width":"100%","background":"#1a1a1c","height":"0.5px"}} />
        <div id="player" style={{"position":"relative","width":"1410px","height":"316px"}}>
          <Player1a />
          <Player1b />
          <Mixera />
          <Mixerb />
          <Player2a />
          <Player2b />
        </div>
      </div>
      <div style={{display: 'none'}}><div id="trash" /></div>
      <Settings />
      <div id="bigmsg" className="bigmsg">
        <div id="bigmsg0" className="bigmsg_close">&#10005;</div>
        <div id="bigmsg1" className="bigmsg_title"></div>
        <div id="bigmsg2" className="bigmsg_content" style={{fontSize: 16}}></div>
      </div>
      { /* <div style={{display: 'none'}} id="login-manager">Login</div> */ }
    </div>
  )
}

export default Player;
