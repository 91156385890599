import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import IMixNext from './svg/IMixNext';

function ConnectedSkipButton({ onClick, stateAutomix }) {
  return (stateAutomix !== 'skipping') // Before: mixing
    ? (
      <div className={`flexRow bp-button medium command type-small cf-command`} onClick={onClick(stateAutomix === 'mixing')} >
        <IMixNext size={20} color={`#8E8E93`} className={`cf-icon`} /> Skip
      </div>
    )
    : (
      <div className={`flexRow bp-button medium unavailable type-small cf-command`} >
        <IMixNext size={20} color={`#8E8E93`} className={`cf-icon`} /> Skip
      </div>
    )
}

ConnectedSkipButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  stateAutomix: PropTypes.string,
}

const SkipButton = connect(
  state => ({
    stateAutomix: state.stateAutomix,
  })
)(ConnectedSkipButton);

export default SkipButton;
