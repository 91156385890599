import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { setPlaylistAutomix } from "../automix/redux/actions/index";

function ConnectedEmergency({ selected, load, playlists, currentID, uidAutomix, historyTracks, setPlaylistAutomix }) {
  const [uid, setUID] = useState(null);

  useEffect( () => {
    const iCurrent = playlists.tracks.findIndex( x => x.id === currentID);
    if (playlists.active === iCurrent && playlists.active !== selected) {
      console.log(`*** EMERGENCY NO MIXIN STATE ***`)
      load(iCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentID, load])

  useEffect( () => { // TOGGLE NEW PLAYLIST
    if (currentID && uidAutomix !== uid) {
      setUID(uidAutomix);
      const firstTrack = null; //historyTracks.find( x => x.id === currentID);
      if (firstTrack && !playlists.isFresh) {
        let tracks = playlists.tracks;
        const startIndex = playlists.tracks.findIndex(x => x.id === currentID);
        if (startIndex < 0) { // a track already play but debut playlist
          tracks = [firstTrack, ...tracks];
        } else { // a track already play on the same playlist so continue
          const beforeTracks = [...tracks].splice(0, startIndex);
          const nextTracks = [...tracks].splice(startIndex, tracks.length - startIndex);
          tracks = [firstTrack, ...nextTracks, ...beforeTracks];
        }
        tracks = tracks.reduce( (base, x) => { //To avoid duplicate following tracks because it breaks the next logic visual automix;
          const isFound = base.find( y => x.id === y.id);
          if (!isFound) { base.push(x); }
          return base;
        }, []);
        setPlaylistAutomix({ ...playlists, tracks, active: 0 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidAutomix, currentID])

  return null;
}

ConnectedEmergency.propTypes = {
  selected: PropTypes.number,
  load: PropTypes.func.isRequired,
}

const Emergency = connect(
  state => ({
    playlists: state.playlistAutomix,
    currentID: state.currentIdAutomix,
    uidAutomix: state.uidAutomix,
    historyTracks: state.historyTracks
  }),
  dispatch => ({
    setPlaylistAutomix: obj => dispatch(setPlaylistAutomix(obj)),
  })
)(ConnectedEmergency);

export default Emergency;
