import React from 'react';
import PropTypes from 'prop-types';

function IMixFullScreen({ size, color = `#A0A0A0`, className, onClick }) {
  return (
    <svg className={className} width={size} height={size} onClick={onClick} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M12 4H16V8" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M8 16H4V12" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M16 4L11.3333 8.66667" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M4 15.9999L8.66667 11.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixFullScreen.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default IMixFullScreen;
