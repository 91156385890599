import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function InputBPM({ base, lock }) {
  const inputRef = useRef(null);
  const [activeInputBPM, setInputBPM] = useState(false);
  const [valBPM, setValBPM] = useState(base);
  useEffect(() => {
    if (!activeInputBPM) {
      setValBPM(base)
    }
  }, [base, activeInputBPM]);
  const showInputBPM = () => {
    setInputBPM(true);
    if (document.getElementById(`iMixSub`)) {
      document.getElementById(`iMixSub`).style.display = `none`;
      document.getElementById(`iMixAdd`).style.display = `none`;
    }
    setTimeout(() => { inputRef.current.focus() }, 200);
  }
  const setValueInput = (e) => { setValBPM(e.target.value); }
  const onEnterPress = (e) => {
    if (e.charCode === 13 ) {
      changeDirectBPM();
    }
  }
  const changeDirectBPM = () => {
    let val = valBPM;
    if (valBPM - base > 40) {
      val = base + 40;
    } else if (base - valBPM > 40) {
      val = base - 40;
    }
    window['YouDJ'] && window['YouDJ'].automix_bpm_value(val);
    setInputBPM(false);
    if (document.getElementById(`iMixSub`)) {
      document.getElementById(`iMixSub`).style.display = `initial`;
      document.getElementById(`iMixAdd`).style.display = `initial`;
    }
  }

  return activeInputBPM
    ? <input ref={inputRef} value={valBPM} type={`number`} min={10} max={250} maxLength={3} className={`bpm-input`} onChange={setValueInput} onKeyPress={onEnterPress} onBlur={changeDirectBPM} />
    : <span className={`bpm-automix`} onClick={!lock ? showInputBPM : null}>{base}</span>
}

InputBPM.propTypes = {
  base: PropTypes.number,
  lock: PropTypes.bool.isRequired,
}

export default InputBPM;
