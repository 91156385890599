export default function mergeReducers(list) {
  const mainReducer = (state, action) => {
    return list.reduce((base, reducer, index) => {
      return (typeof state === 'undefined')
        ? { ...base, ...reducer(state, action) }
        : reducer( (index === 0) ? state : base, action)
    }, {});
  }
  return mainReducer;
}
