import React from 'react';
import PropTypes from 'prop-types';

function IMixToggle({ className, style, onClick }) {
  return (
    <div className={className} style={style} onClick={onClick}>
      <svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="32" rx="16" fill="#9500FF"/>
        <path d="M18.604 15.8C18.604 16.616 18.432 17.348 18.088 17.996C17.744 18.644 17.26 19.152 16.636 19.52C16.02 19.888 15.316 20.072 14.524 20.072C13.732 20.072 13.024 19.888 12.4 19.52C11.784 19.152 11.304 18.644 10.96 17.996C10.616 17.348 10.444 16.616 10.444 15.8C10.444 14.984 10.616 14.252 10.96 13.604C11.304 12.956 11.784 12.448 12.4 12.08C13.024 11.712 13.732 11.528 14.524 11.528C15.316 11.528 16.02 11.712 16.636 12.08C17.26 12.448 17.744 12.956 18.088 13.604C18.432 14.252 18.604 14.984 18.604 15.8ZM11.86 15.8C11.86 16.384 11.972 16.912 12.196 17.384C12.42 17.848 12.732 18.212 13.132 18.476C13.54 18.732 14.004 18.86 14.524 18.86C15.044 18.86 15.504 18.732 15.904 18.476C16.312 18.212 16.628 17.848 16.852 17.384C17.076 16.912 17.188 16.384 17.188 15.8C17.188 15.216 17.076 14.692 16.852 14.228C16.628 13.756 16.312 13.392 15.904 13.136C15.504 12.872 15.044 12.74 14.524 12.74C14.004 12.74 13.54 12.872 13.132 13.136C12.732 13.392 12.42 13.756 12.196 14.228C11.972 14.692 11.86 15.216 11.86 15.8ZM22.9829 13.784C23.7109 13.784 24.3029 14.008 24.7589 14.456C25.2229 14.904 25.4549 15.624 25.4549 16.616V20H24.1469V16.688C24.1469 16.104 24.0229 15.66 23.7749 15.356C23.5269 15.052 23.1629 14.9 22.6829 14.9C22.1709 14.9 21.7669 15.08 21.4709 15.44C21.1829 15.792 21.0389 16.28 21.0389 16.904V20H19.7429V13.856H20.8709L21.0149 14.66C21.4789 14.076 22.1349 13.784 22.9829 13.784Z" fill="white"/>
        <circle cx="48" cy="16" r="12" fill="#E5E5E5"/>
      </svg>
    </div>
  )
}

IMixToggle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
}

export default IMixToggle;
