import React from 'react';
import PropTypes from 'prop-types';

function IMixAdd({ size, color = `#A0A0A0`, className, onClick }) {
  const onMouseDown = () => { onClick(true); }
  const onMouseUp = () => { onClick(false); }

  return (
    <svg id={`iMixAdd`} className={className} width={size} height={size} onMouseDown={onMouseDown} onMouseUp={onMouseUp} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M10 4V16" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M4 10H16" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixAdd.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default IMixAdd;
