import { createStore } from "redux";
import mergeReducers from "../reducers/utils";
import rootReducer from "../reducers/index"; // CORE

import automixReducer from "../components/automix/redux/reducers/index";
const customReducers = [
  automixReducer
];

const reducers = mergeReducers([rootReducer, ...customReducers]);
const store = createStore(reducers);

export default store;
