import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './components/Root';

let root = null;
const elRoot = document.getElementById('root-dev');
if (elRoot) {
  root = createRoot(elRoot);
  root.render(
    <Root />
  );
}

const cFeature = () => {
  console.log('call a custom feature');
}

const cRender = (elementID, value) => {
  const el = document.getElementById(elementID);
  if (el) {
    root = root || createRoot(el);
    // <Player value={value} />
    root.render(
      <Root />
    );
    console.log('update root render');
  }
}

const sdk = {
  cFeature,
  cRender
}

export default sdk;
