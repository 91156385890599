// Redux Reducers
import {
  SET_ONAIR
} from "../types";


const initialState = {
  onAir: [null, null]
};

function rootReducer(state = initialState, action) {

  /** ON AIR **/

  if (action.type === SET_ONAIR) {
    let newOnAir = [...state.onAir];
    newOnAir[action.pid - 1] = action.trackID;
    return { ...state, onAir: [...newOnAir] };
  }

  return state;
};

export default rootReducer;
