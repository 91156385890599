// import * as Config from '../../config';
import { useCallback, useContext } from "react";
import { useDispatch, ReactReduxContext } from 'react-redux';

import {
  setOnAir
} from "../../../actions/index"

import {
  enableAutomix,
  setStoreAutomix,
  setPlaylistAutomix,
  setUidAutomix,
  loadIndexAutomix
} from "../redux/actions/index";

// const axios = require('axios');

// Store Automix Management
const useAutomix = () => {
  const { store } = useContext(ReactReduxContext)
  const { getState } = store;
  const dispatch = useDispatch();

  dispatch(enableAutomix(true));

/*
  const loadAudio = async (pid, track) => {
    // HLS
    axios.get(`https://api.beatport.com/v4/catalog/tracks/${track.id}/stream/`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }}).then( response => {

      // track = Config.formatForPlayer(track, pid);
      console.log('AUTOMIX LOAD', track, pid)
      YouDJ.loadsong_mp4(pid, track.url, track.title, track.tag, track.keys, track.length, track.id, track.img, track.autoplay, track.start_s, track.end_s, track.isBroadcast);

    }).catch( e => {
      console.log(JSON.parse(JSON.stringify(e, null, 2)));
    });
  }
  */

  const load = useCallback((pid, isFresh = false) => {
    const state = getState();
    if (state.playlistAutomix.tracks.length > 0) {
      const actualPlaylist = [...state.playlistAutomix.tracks];

      let loadIndex = 0;
      if (!state.loadIndexAutomix) {
        let checkPIDIndex = null;
        checkPIDIndex = (pid === 1) ? 1 : 0;
        const checkTrackID = (state.onAir) ? state.onAir[checkPIDIndex] : null;
        if (checkTrackID) {
          const checkIndex = actualPlaylist.findIndex( x => checkTrackID === x.id );
          loadIndex = (checkIndex < 0) ? 0 : ((checkIndex + 1 < actualPlaylist.length) ? checkIndex + 1 : -1)
        }
      } else { // FORCE LOAD INDEX
        loadIndex = state.loadIndexAutomix
      }

      let track = actualPlaylist.find( (x, i) => i === loadIndex );
      // loadAudio(pid, track); // TODO WORK

      // track = Config.formatForPlayer(track, pid);
      console.log('AUTOMIX LOAD', track, pid)
      window.YouDJ.loadsong_mp4(pid, track.url, track.title, track.tag, track.keys, track.length, track.id, track.img, track.autoplay, track.start_s, track.end_s, track.isBroadcast);

      dispatch(setPlaylistAutomix({ tracks: [...actualPlaylist], from: state.playlistAutomix.from, active: loadIndex, next: state.playlistAutomix.next, isFresh }));
      dispatch(setOnAir(pid, track.id));
      dispatch(loadIndexAutomix(null));

      if (loadIndex === -1 || loadIndex + 1 === actualPlaylist.length) { // End playlist stop loop
        setTimeout(() => { window['YouDJ'] && window['YouDJ'].automix_off(); }, 20000)
      }

    }
  }, [dispatch, getState])

  const disable = () => { dispatch(setUidAutomix(null)) }

  const storeX = { load, disable };
  setTimeout(() => { dispatch(setStoreAutomix(storeX)) }, 100);
  return null;
};

export default useAutomix;
