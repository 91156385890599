import React from 'react';
import PropTypes from 'prop-types';

function IMixNext({ size, color = `#A0A0A0`, className }) {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`icon-automix`} d="M4.16675 4L12.7382 10L4.16675 16V4Z" fill={color} stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path className={`icon-automix`} d="M16.1667 4.75015V15.2502" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

IMixNext.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
}

export default IMixNext;
