// Redux Actions
import {
  SET_ONAIR
} from "../types";

/** ON AIR **/

export function setOnAir(pid, trackID) {
  return { type: SET_ONAIR, pid, trackID }
};
