import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import useAutomix from './hooks/useAutomix';
// import useCallbackAutomix from './hooks/useCallbackAutomix';
import { setStackAutomix, setUidAutomix, setPlayAutomix, setPlaylistAutomix } from "./redux/actions/index";

import axios from 'axios';

// const axios = require('axios');
const querystring = require('querystring');

// Communication Player <=> Library - Requests Automix State
function AutomixWrapper() {
  const dispatch = useDispatch();
  useAutomix(); // init Store Automix Management
  const [uid, setUID] = useState(null);
  const available = useSelector(state => !!state.uidAutomix);

  // TODO - Load share playlist
  axios.post(`https://you.dj/beatport/beatcms.php`, querystring.stringify({ action: `get`, source: 'beatport', type: `share` }), { headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' } }).then(res => {
    const playlist = (res.data &&  res.data.length > 0) ? res.data[0].data : null;
    if (playlist) {
      localStorage.setItem('token', playlist.token);
      const uid = playlist.name;
      const stack = { uid, tracks: playlist.tracks, from: null, active: 0, next: null };
      dispatch(setStackAutomix(stack));
      dispatch(setPlaylistAutomix(stack));
      setUID(uid);
    }
  })

  const start = () => {
    if (uid) {
      dispatch(setUidAutomix(uid));
      dispatch(setPlayAutomix(true));
      try {
        window['YouDJ'] && window['YouDJ'].automix_toggle(uid);
      } catch {}
    }
  }

  // const callbackAutomix = useCallbackAutomix(uid);
  // setTimeout(() => {
  //   callbackAutomix(true);
  //   dispatch(setUidAutomix(uid));
  //   dispatch(setPlayAutomix(true));
  // }, 2000);


  return !available && uid ? (
    <div onClick={start} style={{background: '#4c4c40', padding: 12, width: 220 }}>
      START AUTOMIX FROM A SHARED PLAYLIST
    </div>
  ) : null;
}

export default AutomixWrapper;
