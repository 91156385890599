import React from 'react';

function Player2a() {
  return (
    <div id="player2a" style={{"position":"absolute","top":"10px","left":"835px","width":"575px","height":"85px","zIndex":"2"}}>
      <div id="image2" style={{"position":"absolute","top":"0px","right":"9px","width":"40px","height":"40px","background":"#09090B","left":"0px"}} src="/picx_bp/vinyl_default2.png" width="40" height="40" />
      <div id="song2" className="song_text cropped nicefont" style={{"position":"absolute","top":"-2px","left":"-3px","width":"430px","padding":"1px 0px 3px 3px"}}>Load a song..</div>
      <div id="time2" className="time_text" style={{"position":"absolute","top":"-0.5px","right":"64px"}}>
        <span id="time2a">0:00</span> / <span id="time2b">4:00</span>
      </div>
      <div id="key2" className="key_text nicefont" style={{"position":"absolute","top":"21px","right":"64px"}}></div>
      <div id="tag2" className="tag_text cropped nicefont" style={{"position":"absolute","top":"21px","left":"0px","width":"483px"}}></div>
      <canvas id="jump2_preview" style={{"position":"absolute","top":"39px","left":"0px","opacity":"0.5"}} width="566" height="36"></canvas>
      <div id="jump2" className="pointer" style={{"position":"absolute","top":"39px","left":"0px","width":"566px","height":"45px"}}>
        <div style={{"position":"absolute","top":"0px","left":"-10px","width":"15px","height":"45px"}}></div>
        <canvas id="jump2_canvas0" style={{"position":"absolute","top":"0px","left":"0px","opacity":"0.4"}} width="566" height="36"></canvas>
        <canvas id="jump2_canvas1" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(0px,0px,40px,0px)"}} width="566" height="36"></canvas>
        <canvas id="jump2_canvas2" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(0px,0px,40px,0px)"}} width="566" height="36"></canvas>
        <svg id="jump2_outro" style={{"display":"none","position":"absolute","top":"11px","left":"0px"}} width="3" height="25">
          <path stroke="#FFF" strokeWidth="1" d="M1,0 v25" />
        </svg>
        <svg id="jump2_hover" style={{"display":"none","position":"absolute","top":"11px","left":"0px"}} width="5" height="25">
          <path stroke="#FFF8" strokeWidth="3" d="M1,0 v25" />
        </svg>
        <svg id="jump2_cursor" style={{"position":"absolute","top":"11px","left":"0px"}} width="5" height="25">
          <path stroke="#FFF" strokeWidth="3" d="M1,0 v25" />
        </svg>
        <div id="jump2_hotcue" style={{"position":"absolute","top":"0px","left":"0px"}} />
      </div>
    </div>
  )
}

export default Player2a;
