import React from 'react';

function Extra() {
  return (
    <>
      <div id="splash" style={{ display: 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, background: '#000' }}>
        <div id="splash_loading" style={{ position: 'absolute', top: 0, left: 0, width:0, height: 4, background: '#FFF', transition: 'width 1.3s' }} />
      </div>
      <div id="blurfirst" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',  zIndex: 1050, overflow: 'hidden auto', textAlign: 'center', background: 'rgba(18, 18, 18, 0.96) !important', backgroundSize: '4px 4px !important', display: 'none' }} />
      <div id="big_bg" className="big_bg" style={{ display: 'none' }} />
      <div id="tooltip" className="tooltip hidethis" style={{ position: 'absolute', zIndex: 2002, top: 0, left: 0 }} />
      <div id="browser" className="browser nicefont" style={{ display: 'none', position: 'absolute', bottom: 0, left: 0, zIndex: 9999, opacity: 0 }} />
    </>
  )
}

export default Extra;
