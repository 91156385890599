import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Progress from './Progress';

function ConnectedState({ tracks, active, width, onClick, title, to, stateAutomix }) {
  const byItem = ((width - 64 - (tracks.length * 4)) / tracks.length) - 1;
  return (
    <div className={`cf-state-nav flexColumn`}>
      <span className={`cf-state-nav-title type-title`}>
        {title}
        {(stateAutomix === 'mixing') && <Progress />}
        <span className={`type-regular type-secondary cf-state-nav-count`}>{`${active + 1} / ${tracks.length}`}</span>
      </span>
      <div className={`flexRow`} style={{ zIndex: 1}}>
        {tracks.map((track, i) => (
          <div key={`cf-nav-item${i}`} className={`cf-nav-item ${i <= active && `active`} ${i}`} style={{ width: byItem }} onClick={onClick(i)} />
        ))}
      </div>
    </div>
  )
}

ConnectedState.propTypes = {
  tracks: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  title: PropTypes.string,
  stateAutomix: PropTypes.string
}

const State = connect(
  state => ({
    stateAutomix: state.stateAutomix,
  })
)(ConnectedState);

export default State;
