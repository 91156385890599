// Redux Reducers
import {
  SET_ENABLED_AUTOMIX,
  SET_STORE_AUTOMIX,
  SET_PLAYLIST_AUTOMIX,
  SET_STACK_AUTOMIX,
  SET_UID_AUTOMIX,
  LOAD_INDEX_AUTOMIX,
  SET_RANDOM_AUTOMIX,
  SET_PLAY_AUTOMIX,
  SET_AUTOBPM_AUTOMIX,
  SET_BPM_AUTOMIX,
  SET_CURRENT_PLAY_AUTOMIX,
  SET_CURRENT_AUTOMIX,
  SET_STATE_AUTOMIX,
  SET_SETTING_HIDE_AUTOMIX,
  SET_CURRENTID_AUTOMIX,
  SET_QUEUE_AUTOMIX,
} from "../types";

const initialState = {
  enabledAutomix: false,
  storeAutomix: {},
  playlistAutomix: { tracks: [], active: 0, next: null, isFresh: false },
  stackAutomix: { tracks: [], active: 0, next: null },
  uidAutomix: null,
  loadIndexAutomix: null,
  isRandomAutomix: false,
  isPlayingAutomix: false,
  autoBpmAutomix: false,
  bpmAutomix: null,
  isCurrentPlayAutomix: null,
  stateAutomix: null,
  currentAutomix: { autobpm: null, bpm: null, position: null, state: null, playing: null },
  isSettingHideAutomix: false,
  currentIdAutomix: null
};

const shuffle = (array) => {
  var currentIndex = array.length
    , temporaryValue
    , randomIndex
    ;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

const sortNumeric = (property, asc) => (a, b) => {
  const aNumber = parseInt(a[property]);
  const bNumber = parseInt(b[property]);
  if (aNumber < bNumber) { return (asc) ? -1 : 1 }
  if (aNumber > bNumber) { return (asc) ? 1 : -1 }
  return 0;
}

function automixReducer(state = initialState, action) {

  if (action.type === SET_ENABLED_AUTOMIX) {
    return { ...state, enabledAutomix: action.payload }
  }

  if (action.type === SET_STORE_AUTOMIX) {
    return { ...state, storeAutomix: {...action.payload} }
  }

  if (action.type === SET_PLAYLIST_AUTOMIX) {
    return { ...state, playlistAutomix: { ...action.payload } }
  }

  if (action.type === SET_STACK_AUTOMIX) {
    const newStack = (!action.payload.uid) ? { ...action.payload } : (Array.isArray(state.stackAutomix)) ? [...state.stackAutomix, { ...action.payload }] : [{ ...action.payload }];
    return { ...state, stackAutomix: newStack }
  }

  if (action.type === SET_UID_AUTOMIX) {
    return { ...state, uidAutomix: action.payload }
  }

  if (action.type === LOAD_INDEX_AUTOMIX) {
    return { ...state, loadIndexAutomix: action.payload }
  }

  if (action.type === SET_RANDOM_AUTOMIX) {
    let playlistAutomix = { ...state.playlistAutomix };
    let second = [...state.playlistAutomix.tracks];
    const first = second.splice(0, state.playlistAutomix.active + 1);
    second = (action.payload) ? shuffle(second) : second.sort(sortNumeric(`top`, true));
    playlistAutomix = { ...state.playlistAutomix, tracks: [...first, ...second] };
    return { ...state, isRandomAutomix: action.payload, playlistAutomix }
  }

  if (action.type === SET_QUEUE_AUTOMIX) {
    let playlistAutomix = { ...state.playlistAutomix };
    let second = [...state.playlistAutomix.tracks];
    const trackInQueue = second.splice(action.payload, 1).shift()
    const first = second.splice(0, state.playlistAutomix.active + 1);
    playlistAutomix = { ...state.playlistAutomix, tracks: [...first, trackInQueue, ...second] };
    return { ...state, playlistAutomix }
  }

  if (action.type === SET_PLAY_AUTOMIX) {
    return { ...state, isPlayingAutomix: action.payload  }
  }

  if (action.type === SET_AUTOBPM_AUTOMIX) {
    return { ...state, autoBpmAutomix: action.payload  }
  }

  if (action.type === SET_BPM_AUTOMIX) {
    return { ...state, bpmAutomix: action.payload  }
  }

  if (action.type === SET_CURRENT_PLAY_AUTOMIX) {
    return { ...state, isCurrentPlayAutomix: action.payload  }
  }

  if (action.type === SET_CURRENT_AUTOMIX) {
    return { ...state, currentAutomix: action.payload  }
  }

  if (action.type === SET_STATE_AUTOMIX) {
    return { ...state, stateAutomix: action.payload  }
  }

  if (action.type === SET_SETTING_HIDE_AUTOMIX) {
    return { ...state, isSettingHideAutomix: action.payload  }
  }

  if (action.type === SET_CURRENTID_AUTOMIX) {
    return { ...state, currentIdAutomix: action.payload  }
  }

  return state;
};

export default automixReducer;
