import React from 'react';

function Mixera() {
  return (
    <div id="mixera" style={{"position":"absolute","top":"10px","left":"596px","width":"218px","height":"85px","zIndex":"3"}}>
      <div id="portal-volume" />
      <div id="vumaster" style={{"position":"absolute","top":"0px","left":"6px"}}>
        <svg style={{"position":"absolute","top":"0px","left":"0px","fill":"#333335"}} height="8" width="205">
          <rect x="0" y="1" width="205" height="6" />
        </svg>
        <svg style={{"position":"absolute","top":"9px","left":"0px","fill":"#333335"}} height="8" width="205">
          <rect x="0" y="1" width="205" height="6"/>
        </svg>
        <svg id="vumeter0a" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(0px,3px,8px,0px)","fill":"#9B9B9B"}} height="8" width="205">
          <rect x="0" y="1" width="205" height="6"/>
        </svg>
        <svg id="vumeter0b" style={{"position":"absolute","top":"9px","left":"0px","clip":"rect(0px,3px,8px,0px)","fill":"#9B9B9B"}} height="8" width="205">
          <rect x="0" y="1" width="205" height="6"/>
        </svg>
        <svg id="peak0a" style={{"position":"absolute","top":"0px","left":"199px","fill":"#333335"}} height="8" width="8">
          <rect x="0" y="1" width="6" height="6"/>
        </svg>
        <svg id="peak0b" style={{"position":"absolute","top":"9px","left":"199px","fill":"#333335"}} height="8" width="8">
          <rect x="0" y="1" width="6" height="6"/>
        </svg>
      </div>
      <div id="pro1_sync" style={{"position":"absolute","left":"6px","top":"26.5px"}} className="button buttonsync button_active1 nicefont">Sync</div>
      <div id="pro2_sync" style={{"position":"absolute","left":"164px","top":"26.5px"}} className="button buttonsync button_active2 nicefont">Sync</div>
      <div id="bpm_button" className="bpm_button" style={{"position":"absolute","height":"30px","width":"102px","top":"22.5px","left":"58px"}}>
        <div id="bpm_button_value" />
        <div id="bpm_button_auto" className="nicefont" style={{"color":"#71e59b","display":"none","position":"absolute","top":"14px","left":"23px","fontSize":"8px","letterSpacing":"0.5px"}}>AUTOBPM ON</div>
      </div>
      <div id="beatvisu" style={{"position":"absolute","top":"59px","left":"6px"}}>
        <div style={{"position":"absolute","width":"205px","height":"16px","top":"0px","left":"0px","background":"#29292b"}} />
        <div id="beatvisu1" style={{"position":"absolute","width":"205px","height":"8px","top":"0px","left":"0px","background":"#4EDF84","clip":"rect(0px,0px,0px,0px)"}} />
        <div id="beatvisu2" style={{"position":"absolute","width":"205px","height":"8px","top":"8px","left":"0px","background":"#31BAFF","clip":"rect(0px,0px,0px,0px)"}} />
        <svg style={{"position":"absolute","top":"0px","left":"0px"}} height="16" width="205">
          <line x1="51" y1="0" x2="51" y2="16" strokeWidth="2" stroke="#131315" />
          <line x1="103" y1="0" x2="103" y2="16" strokeWidth="2" stroke="#131315" />
          <line x1="154" y1="0" x2="154" y2="16" strokeWidth="2" stroke="#131315" />
          <line x1="0" y1="8" x2="205" y2="8" strokeWidth="2" stroke="#131315" />
        </svg>
      </div>
      <div id="phasevisu" style={{"display":"none","position":"absolute","top":"59px","left":"6px","width":"205px","height":"16px","background":"#272729"}}>
        <div id="phasevisu_cursor" style={{"position":"absolute","top":"0px","left":"102px","width":"0px","height":"16px"}} />
        <div style={{"position":"absolute","top":"0px","left":"102px","width":"2px","height":"16px","background":"#FFF"}} />
      </div>
      <div id="bpm_menu" className="bpm_menu" style={{"display":"none","position":"absolute","top":"59px","left":"-44px"}}>
        <div id="bpm_menu_value" className="center" style={{"position":"absolute","top":"0px","left":"0px","height":"40px","lineHeight":"41px","background":"#000"}} />
        <div onMouseDown={() => { /* bpm_menu(false) */ } } className="bpm_menu_close" style={{"position":"absolute","right":"0px","top":"0px","width":"30px","height":"30px"}}>
          <svg style={{"position":"absolute","left":"9px","top":"7px"}} width="15" height="15">
            <path fill="none" strokeWidth="2.5" d="M1,1 L14,14 M14,1 L1,14" />
          </svg>
        </div>
        <div id="automix_panel" style={{"display":"none","position":"absolute","top":"60px","left":"0px","width":"310px"}}>
          <div className="center" style={{"position":"absolute","top":"0px"}}>The mixer is currently automatically mixing :</div>
          <div id="automix_info" className="center" style={{"position":"absolute","top":"27px","color":"#F70"}} />
          <div className="center" style={{"position":"absolute","top":"66px"}}>
            <span className='link_botton_orange' onMouseUp={() => { /* automix_cancel() */ } }>STOP AUTOMIX</span>&nbsp;&nbsp;<span className='link_botton_grey nextactive' onMouseUp={() => { /* automix_nextnow() */ }}>next song ></span>
          </div>
          <div className="center" style={{"position":"absolute","top":"127px"}}>
            <input onClick={() => { /* 'settings_cb(this)' */ }} type='checkbox' id='a07b' className='css-checkbox'/>
            <label htmlFor='a07b' className='css-label' style={{fontSize: 14}}>enable random automixing</label>
          </div>
        </div>
        <div id="bpm_panel" style={{"display":"none","position":"absolute","top":"60px","left":"0px","width":"310px"}}>
          <div id="bpm_coef_info" className="center color1" style={{"display":"none","position":"absolute","top":"0px"}}>...</div>
          <div id="bpm_coef" style={{"display":"none","position":"absolute","top":"37px","left":"10px","color":"#999","background":"#333335","width":"290px","height":"26px","cursor":"pointer"}}>
            <svg style={{"position":"absolute","top":"12.5px","left":"9px"}} width="12" height="12">
              <path fill="none" stroke="#999" strokeWidth="2" d="M 1 1 L 9 1" />
            </svg>
            <div style={{"position":"absolute","top":"4px","left":"272px","color":"#999","fontSize":"20px"}}>+</div>
            <div style={{"position":"absolute","top":"0px","left":"145px","background":"#999","width":"1px","height":"26px"}} />
            <div id="bpm_coef_cursor" className="bpm_cursor" style={{"position":"absolute","top":"0px","left":"132px"}}>
              <div style={{"position":"absolute","top":"0px","left":"12px","background":"#FFF","width":"2px","height":"26px"}} />
            </div>
          </div>
          <div id="bpm_master" className="center color1" style={{"display":"block","position":"absolute","top":"0px","left":"10px","width":"290px"}}>manual bpm mode
            <div id="bpm_inc-" className="bpm_master" style={{"position":"absolute","top":"30px","left":"48px"}}>
              <svg style={{"position":"absolute","top":"15px","left":"20px"}} width="12" height="12">
                <path fill="none" stroke="#FFF" strokeWidth="2" d="M 1 1 L 10 1" />
              </svg>
            </div>
            <input id="bpm_input" className="bpm_master" onChange={() => {}} style={{"position":"absolute","top":"30px","left":"101px","width":"73px","border":"none","padding":"0 17px 0 0"}} type="text" autoComplete="off" value="- - -" minLength="2" maxLength="5" />
            <div id="bpm_inc+" className="bpm_master" style={{"position":"absolute","top":"30px","left":"194px"}}>+</div>
            <svg style={{"position":"absolute","top":"39px","left":"170.5px","fill":"#FFF","pointerEvents":"none"}} viewBox="0 0 512 512" height="12" width="12">
              <path d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z" />
            </svg>
          </div>
          <div id="bpm_manual" className="center" style={{"display":"none","position":"absolute","color":"#999","top":"20px","left":"10px","width":"290px"}}><u>info</u> : to adjust the BPM use the pitch control on each deck.</div>
          <div id="bpm_info1" className="center" style={{"position":"absolute","top":"130px","left":"0px","width":"154px","height":"32px","lineHeight":"32px","color":"#999","borderTop":"solid 1px #222"}}>...</div>
          <div id="bpm_info2" className="center" style={{"position":"absolute","top":"130px","left":"154px","width":"154px","height":"32px","lineHeight":"32px","color":"#999","borderTop":"solid 1px #222","borderLeft":"solid 1px #222"}}>...</div>
          <div className="center" style={{"position":"absolute","top":"86px"}}>
            <input onClick={() => { /* "settings_cb(this)" */ }} type="checkbox" id="b06b" className="css-checkbox" />
            <label htmlFor="b06b" className="css-label" style={{fontSize: 14}}>enable AutoBPM</label>
            <span className="info1" />
            <div id="bpm_dotrui_ghion" />
          </div>
        </div>
      </div>
      <div id="arrow1" className="butarrow" style={{"position":"absolute","top":"271px","left":"10px","stroke":"#FFF"}} onMouseDown={ () => { /* mixer_fadeto(1) */ } }>
        <svg style={{"position":"absolute","top":"5px","left":"5.5px"}} width="12" height="12" >
          <path fill="none" strokeWidth="1.5" d="M 8 1 L 2 6 L 8 11"/>
        </svg>
      </div>
      <div id="arrow2" className="butarrow" style={{"position":"absolute","top":"271px","left":"185px","stroke":"#FFF"}} onMouseDown={ () => { /* mixer_fadeto(2) */ } }>
        <svg style={{"position":"absolute","top":"5px","left":"6.5px"}} width="12" height="12" >
          <path fill="none" strokeWidth="1.5" d="M 2 1 L 8 6 L 2 11"/>
        </svg>
      </div>
      <div id="crossfader" className="pointer" style={{"position":"absolute","top":"262px","left":"36px"}}>
        <svg style={{"position":"absolute","top":"0px","left":"0px"}} height="40" width="150">
          <line x1="72" y1="12" x2="72" y2="28" strokeWidth="3" stroke="#555" />
          <line x1="6" y1="20" x2="139" y2="20" strokeWidth="3" stroke="#000" />
        </svg>
        <svg id="crossfader_colors" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(15px,72px,25px,72px)"}} height="40" width="150">
          <line x1="6" y1="20" x2="72" y2="20" strokeWidth="3" stroke="#4EDF84" />
          <line x1="72" y1="20" x2="139" y2="20" strokeWidth="3" stroke="#31BAFF" />
        </svg>
        <div id="crossfader_cursor" style={{"position":"absolute","top":"6px","left":"75px","width":"16px","height":"27px","background":"rgb(255, 255, 255)","borderRadius":"1px","boxShadow":"rgb(0, 0, 0) 0px 0px 6px","transform":"translate3d(-11px, 0px, 0px)"}} className="" />
      </div>
    </div>
  )
}

export default Mixera;
