import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

function WaveformIdle({ id, index, src, smooth = false, duration }) {
  const [dimension, setDimension] = useState(null);

  const draw = useCallback((img) => () => {
    let canvas = document.getElementById(`wc${index}`);
    if (canvas) {
      let ctx = canvas.getContext("2d");
      const dpi = window.devicePixelRatio;
      ctx.scale(dpi, dpi);
      ctx.imageSmoothingQuality = `high`;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = "source-atop";
      ctx.fillStyle = `#525252`; //Config.BLACK_5;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  }, [index]);

  useEffect(() => {
    if (!dimension) {
      setTimeout(() => {
        const el = document.getElementById(`block${index}`);
        if (el) {
          setDimension({ width: (el.getAttribute(`basewidth`) * 2) , height: Math.round(el.getAttribute(`basewidth`) * 0.35) })
        }
      }, 100)
    } else {
      var img = new Image();
      img.onload = draw(img);
      if (src) {
        img.src = src.replace(`1500x250`, `${dimension.width}x${dimension.height}`);
      }
    }
  }, [dimension, draw, index, src])

  const dpi = window.devicePixelRatio;
  return (dimension && src) ? (
    <>
      <canvas id={`wc${index}`} width={dimension.width * dpi} height={dimension.height * dpi} style={{width: dimension.width, height: dimension.height}} />
    </>
  ) : null;
}

WaveformIdle.propTypes = {
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  duration: PropTypes.any.isRequired,
  smooth: PropTypes.bool,
}

export default WaveformIdle;
