import React from 'react';

function Player1a() {
  return (
    <div id="player1a" style={{"position":"absolute","top":"10px","left":"0px","width":"575px","height":"85px","zIndex":"2"}}>
      <div id="image1" style={{"position":"absolute","top":"0px","left":"0px","width":"40px","height":"40px","background":"#09090B"}} src="/picx_bp/vinyl_default1.png" width="40" height="40" />
      <div id="song1" className="song_text cropped nicefont" style={{"position":"absolute","top":"-2px","left":"60px","width":"430px","padding":"1px 0px 3px 3px"}}>Load a song..</div>
      <div id="time1" className="time_text" style={{"position":"absolute","top":"-0.5px","right":"0px"}}>
        <span id="time1a">0:00</span> / <span id="time1b">4:00</span>
      </div>
      <div id="key1" className="key_text nicefont" style={{"position":"absolute","top":"21px","right":"0px"}} />
      <div id="tag1" className="tag_text cropped nicefont" style={{"position":"absolute","top":"21px","left":"63px","width":"480px"}} />
      <canvas id="jump1_preview" style={{"position":"absolute","top":"39px","left":"9px","opacity":"0.5"}} width="566" height="36" />
      <div id="jump1" className="pointer" style={{"position":"absolute","top":"39px","left":"9px","width":"566px","height":"45px"}}>
        <div style={{"position":"absolute","top":"0px","left":"-10px","width":"15px","height":"45px"}} />
        <canvas id="jump1_canvas0" style={{"position":"absolute","top":"0px","left":"0px","opacity":"0.4"}} width="566" height="36" />
        <canvas id="jump1_canvas1" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(0px,0px,40px,0px)"}} width="566" height="36" />
        <canvas id="jump1_canvas2" style={{"position":"absolute","top":"0px","left":"0px","clip":"rect(0px,0px,40px,0px)"}} width="566" height="36" />
        <svg id="jump1_outro" style={{"display":"none","position":"absolute","top":"11px","left":"0px"}} width="3" height="25"><path stroke="#FFF" strokeWidth="1" d="M1,0 v25" /></svg>
        <svg id="jump1_hover" style={{"display":"none","position":"absolute","top":"11px","left":"0px"}} width="5" height="25"><path stroke="#FFF8" strokeWidth="3" d="M1,0 v25" /></svg>
        <svg id="jump1_cursor" style={{"position":"absolute","top":"11px","left":"0px"}} width="5" height="25"><path stroke="#FFF" strokeWidth="3" d="M1,0 v25" /></svg>
        <div id="jump1_hotcue" style={{"position":"absolute","top":"0px","left":"0px"}} />
      </div>
    </div>
  )
}

export default Player1a;
