export const SET_ENABLED_AUTOMIX = "SET_ENABLED_AUTOMIX";
export const SET_STORE_AUTOMIX = "SET_STORE_AUTOMIX";
export const SET_PLAYLIST_AUTOMIX = "SET_PLAYLIST_AUTOMIX";
export const SET_UID_AUTOMIX = "SET_UID_AUTOMIX";
export const SET_STACK_AUTOMIX = "SET_STACK_AUTOMIX";
export const LOAD_INDEX_AUTOMIX = "LOAD_INDEX_AUTOMIX";
export const SET_RANDOM_AUTOMIX = "SET_RANDOM_AUTOMIX";
export const SET_PLAY_AUTOMIX = "SET_PLAY_AUTOMIX";
export const SET_AUTOBPM_AUTOMIX = "SET_AUTOBPM_AUTOMIX";
export const SET_BPM_AUTOMIX = "SET_BPM_AUTOMIX";
export const SET_CURRENT_PLAY_AUTOMIX = "SET_CURRENT_PLAY_AUTOMIX";
export const SET_CURRENT_AUTOMIX = "SET_CURRENT_AUTOMIX";
export const SET_STATE_AUTOMIX = "SET_STATE_AUTOMIX";
export const SET_SETTING_HIDE_AUTOMIX = "SET_SETTING_HIDE_AUTOMIX";
export const SET_CURRENTID_AUTOMIX = "SET_CURRENTID_AUTOMIX";
export const SET_QUEUE_AUTOMIX = "SET_QUEUE_AUTOMIX";
